.video-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    height: fit-content;
}

.video-title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 10px;
    width: 50%;
}

.title-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.video-title-text {
    font-size: 4rem;
    margin: 0px;
}

.ostra-image {
    position: absolute;
    width: 50px;
    top: 0px;
    right: -100px;
}

.ostra-image img {
    width: 100%;
    height: 100%;
}

.raya {
    position: absolute;
    width: 350px;
    left: -350px;
    top: -200px;
    z-index: -1;
}

.peces {
    position: absolute;
    width: 350px;
    left: -400px;
    top: 30px;
}

.video-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom:0px;
}

.video-wrapper-inside {
    width: 70%;
    position: relative;
    text-align: center;
}

.next-video {
    position: absolute;
    top: 50%;
    right: -100px;
}

.pez-espada {
    position: absolute;
    width: 300px;
    right: -200px;
    top: -150px;
}

.pez-aleta {
    position: absolute;
    width: 500px;
    left: -400px;
    bottom: -100px;
}

.pez-gordito {
    position: absolute;
    width: 500px;
    left: -250px;
    bottom: -125px;
}

@media (max-width: 1399.98px) { 

}

@media (max-width: 1199.98px) { 

}

@media (max-width: 991.98px) { 
    .video-title-wrapper {
        width: 100%;
    }
    .video-wrapper {
        width: 100%;
    }
    .raya {
        width: 300px;
    }
    .pez-espada {
        position: absolute;
        width: 300px;
        right: -200px;
        top: -150px;
    }
    .pez-aleta {
        position: absolute;
        width: 500px;
        left: -400px;
        bottom: 0px;
    }

    .pez-gordito {
        position: absolute;
        width: 500px;
        left: -150px;
        bottom: -80px;
    }

    .video-wrapper-inside {
        width: 80%;
    }
}


@media (max-width: 767.98px) { 
    .raya {
        width: 200px;
        top: -100px;
        left: -200px;
    }
    .peces {
        width: 250px;
        top: 30px;
        left: -250px;
    }
    .next-video {
        right: -80px;
    }

    .pez-aleta {
        left: -400px;
        bottom: 0px;
    }
    .video-wrapper-inside {
        width: 80%;
    }

    .video-wrapper {
        margin-top: 50px;
    }

}


@media (max-width: 575.98px) { 
    .video-title-wrapper {
        width: 100%;
    }
    .raya {
        width: 180px;
        top: -100px;
        left: -200px;
    }
    .peces {
        width: 230px;
        top: 30px;
        left: -230px;
    }
    .ostra-image {
        width: 30px;
        right: -50px;
    }
    .video-title-text {
        font-size: 6vw;
    }

    .video-wrapper {
        width: 100%;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .next-video {
        right: -40px;
    }

    .pez-espada {
        width: 150px;
        right: -80px;
        top: -80px;
    }

    .pez-aleta {
        width: 250px;
        left: -200px;
        bottom: -50px;
    }

    .pez-gordito {
        width: 250px;
        left: -80px;
        bottom: -100px;
    }

    .video-wrapper-inside {
        width: 85%;
    }
}
