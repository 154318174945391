
div[currlang=en] .title-wrapper {
  text-align:center;
}

div[currlang=en] .whale-button{
	width: 300px;
}
@media (max-width: 991.98px) { 
	div[currlang=en] .bubble-1 {
		top: 7%;
	}
	
	div[currlang=en] .bubble-3 {
		top: 66%;
	}
}