.success-upload {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}

.success-image {
    width: 80vw;
}

.success-image img{
    margin-bottom: 50px;
}

.info-upload-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.social-media-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.socials {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.socials img {
    width: 50px;
    height: 50px;
    margin-bottom: 0px !important;
    margin-right: 10px;
}

.go-back-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.go-back:hover {
    cursor: pointer;
}

.go-back {
    border-radius: 100px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 150px;
}

.go-back p {
    margin: 0px;
    color: white;
}

.upload-text-sucess-chinese {
    font-weight: bolder;
    font-size: 30px;
    margin: 0px;
}

.upload-text-sucess {
    font-weight: bolder;
    font-size: 30px;
    margin: 0px;
}

.share-text {
    font-weight: bolder;
    margin: 0px;
    margin-bottom: 10px;
}

@media (max-width: 1399.98px) {

}

@media (max-width: 767.98px) {
    .upload-text-sucess-chinese .upload-text-sucess {
        font-size: 16px;
    }
}

@media (max-width: 991.98px) { 
    .success-image img {
        margin-bottom: 30px;
    }
    .socials img {
        width: 30px;
        height: 30px;
    }

    .go-back {
        width: 150px;
        height: 40px;
    }
}

@media (max-width: 575.98px) {
    .socials img {
        width: 25px;
        height: 25px;
    }
    .go-back {
        width: 130px;
        height: 30px;
    }
}