.experience-container-new {
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 150px;
    margin-top: 300px;
}

.carousel-image-height {
    /* height: calc(1000px / 1920 * 1080); */
    height: 550px;
}

.title-experience-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.title-ex-text {
    font-size: 4rem;
    margin: 0px;
}

.experience-wrapper {
    width: 80%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.inner-experience-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.experience-image {
    width: 100%;
    display:flex;
    align-items: center;
}

.estrella {
    position: absolute;
    bottom: -150px;
    width: 500px;
    left: -300px;
}

.experience-image img {
    width: 100%;
    border-radius: 20px;
}

.experience-text {
    width: 40%;
    margin-left: 20px;
}

.experience-text-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
}

.tablet-text {
    display: flex;
    align-items: center;
}

.tablet-text img{
    width: 40px;
    height: 40px;
    margin: 0px;
}

.tab-content-text {
    font-size: 1rem;
    padding: 20px;
}

.register-container {
    width: 100%;
    display: flex;
    align-items: center;
}

.button-register {
    background-color: black;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    height: 50px;
    border-radius: 100px;
}

@media (max-width: 1399.98px) { 

}

@media (max-width: 1199.98px) { 

}

@media (max-width: 991.98px) { 
    .tablet-text img {
        width: 35px;
        height: 35px;
    }
    .tab-content-text {
        font-size: .95rem;
    }
    .experience-wrapper {
        width: 100% !important;
    }
    .estrella {
        width: 250px;
        left: -150px;
        bottom: -50px;
    }
    .inner-experience-wrapper {
        padding: 15px;
    }
}

@media (max-width: 767.98px) { 
    .experience-wrapper {
        width: 100% !important;
        flex-direction: column;
    }
    .experience-image {
        width: 100% !important;
    }
    .experience-text {
        width: 70%
    }
    .tab-content-text {
        font-size: .8rem;
    }
    .experience-container-new {
        margin-bottom: 50px;
        margin-top: 250px;
    }
    .estrella {
        width: 250px;
        left: -150px;
        bottom: 50px;
    }
    .title-ex-text {
        font-size: 3rem;
    }

}


@media (max-width: 575.98px) { 
    .tablet-text img {
        width: 30px;
        height: 30px;
    }
    .tab-content-text {
        font-size: 1rem;
    }
    .title-ex-text {
        font-size: 6vw;
    }
    .register-container {
        justify-content: center;
    }
    .experience-container-new {
        margin-bottom: 50px;
        margin-top: 200px;
    }
    .estrella {
        width: 250px;
        left: -150px;
        bottom: 200px;
    }
    .carousel-image-height {
        height: 250px;
    }
    .inner-experience-wrapper {
        padding: 15px;
    }
}
