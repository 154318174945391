.workshop-container {
    margin-bottom: 150px;
}

.workshop-text-container {
    display: flex;
    max-width: 50%;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

@media (max-width: 991.98px) { 
    .workshop-container {
        margin-bottom: 50px;
    }
    .workshop-text-container {
        max-width: 70%;
    }
}

@media (max-width: 575.98px) {
    .workshop-container {
        margin-bottom: 50px;
    }
    .workshop-text-container {
        max-width: 100%;
    }
}