@import url("https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-400.ttf");

.presentation-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    height: 700px;
}

.presentation-wrapper {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-left: 100px;
    padding-right: 100px;
}

.inside-presentation-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.text-container {
    width: 30%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.image-container {
    width: 40%;
    height: 100%;
}

.image-container img {
    width: 100%;
    max-height: 100%;
    border-radius: 20px;
}

.presentation-image {
    border-radius: 20px;
}

.text-presentation {
    font-size: 1rem;
    text-align: left;
}

.text-presentation-disclaimer {
    font-size: .7em;
    text-align: left;
}

.carousel-inner {
    height: 100% !important;
    border-radius: 20px;
    z-index: 1;
}

.carousel-item {
    height: 100% !important;
    border-radius: 20px;
    z-index: 1;
}

.inside-carousel-text {
margin: 0px;
    color: black;
    font-weight: bolder;
}

.inside-carousel-text-disclaimers {
    font-size: .5rem;
    margin: 0px;
    text-align: left;
    color: black;
    font-weight: bolder;
}

@media (max-width: 1399.98px) { 

}

@media (max-width: 1199.98px) { 
    .text-container {
        width: 40%;
    }
    .image-container {
        width: 50%
    }
    .presentation-wrapper {
        width: 100%;
    }
}

@media (max-width: 991.98px) { 
    .presentation-wrapper {
        padding-left: 50px;
        padding-right: 50px;
        width: 100%;
    } 
    .left-side-bottom-carousel {
        display: none;
    }
}


@media (max-width: 767.98px) { 
    .presentation-wrapper {
        padding-left: 0px;
        padding-right: 0px;
    } 
    .presentation-container {
        height: 500px;
    }
    .left-side-bottom-carousel {
        display: none;
    }
}


@media (max-width: 575.98px) { 
    .presentation-container {
        height: 400px;
    }
    .text-presentation {
        font-size: 1rem;
    }
    .inside-carousel-text-disclaimers {
        padding-left: 10px;
        padding-right: 10px;
    }
}
