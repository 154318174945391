.first-carousel-container-new {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 150px;
}

.first-carousel-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;    
}

.first-carousel-text {
    font-size: 4rem;
    margin: 0px;
}


@media (max-width: 1399.98px) { 

}

@media (max-width: 1199.98px) { 

}

@media (max-width: 991.98px) { 
   
}

@media (max-width: 767.98px) { 
    .first-carousel-text {
        font-size: 2.5rem;
    }
    .first-carousel-container-new {
        margin-bottom: 100px;
    }
}

@media (max-width: 575.98px) { 
    .first-carousel-container-new {
        margin-bottom: 100px;
    }
    .first-carousel-text {
        font-size: 6vw;
        margin: 0px;
    }
}
