body, h1, h2, h3, h4, h5, h6 {
    font-family: Microsoft JhengHei,SamsungOne,arial,sans-serif !important;
}

/* overwrrite default style */
.gnb__menu-wrap .open a {
    text-decoration: none !important;
}

.gnb__depth1 {
    padding-left: 0px;
}

/*  */

.bg-black {
    background-color: #000 !important;
    color: #fff
}

.bg-white {
    background-color: #fff !important;
    color: #000
}

.bg-gray {
    background: #f7f7f7 !important
}

.bg-dark-gray {
    background-color: #555 !important;
    color: #fff
}

.bg-light-gray {
    background-color: #f4f4f4 !important;
    color: #000
}

.bg-transparent {
    background-color: transparent !important
}

.text-color--black {
    color: #000 !important
}

.text-color--white {
    color: #fff !important
}

.align-center {
    text-align: center !important
}

.align-left {
    text-align: left !important
}

.align-right {
    text-align: right !important
}

.background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 300;
    color: #000
}

.margin-top--narrow {
    padding-top: 16px
}

.margin-top--normal {
    padding-top: 32px
}

.margin-top--wide {
    padding-top: 56px
}

.margin-bottom--narrow {
    padding-bottom: 16px
}

.margin-bottom--normal {
    padding-bottom: 32px
}

.margin-bottom--wide {
    padding-bottom: 56px
}

.s-buffer-top {
    margin-top: 88px !important
}

.s-buffer-bottom {
    margin-bottom: 88px !important
}

.top-margin-narrow {
    margin-top: 2px !important
}

.top-margin-normal {
    margin-top: 13px !important
}

.top-margin-wide {
    margin-top: 32px !important
}

.top-margin-very-wide {
    margin-top: 42px !important
}

.container-width-large {
    max-width: 1920px !important
}

[data-font-size-pc="64"] {
    font-size: 48px
}

[data-font-size-pc="48"] {
    font-size: 38px
}

[data-font-size-pc="32"] {
    font-size: 32px
}

[data-font-size-pc="24"] {
    font-size: 24px
}

[data-font-size-pc="22"] {
    font-size: 28px
}

[data-font-size-pc="20"] {
    font-size: 22px
}

[data-font-size-pc="18"] {
    font-size: 18px
}

[data-font-size-pc="14"] {
    font-size: 14px
}

.skip-bar a {
    overflow: hidden;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    background: #000;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft JhengHei,SamsungOne,arial,sans-serif
}

    .skip-bar a:focus {
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px
    }

@media only screen and (min-width:768px) and (max-width:1440px) {
    .margin-top--narrow {
        padding-top: 1.11111111vw
    }

    .margin-top--normal {
        padding-top: 2.22222222vw
    }

    .margin-top--wide {
        padding-top: 3.88888889vw
    }

    .margin-bottom--narrow {
        padding-bottom: 1.11111111vw
    }

    .margin-bottom--normal {
        padding-bottom: 2.22222222vw
    }

    .margin-bottom--wide {
        padding-bottom: 3.88888889vw
    }

    [data-font-size-pc="64"] {
        font-size: 3.33333333vw
    }

    [data-font-size-pc="48"] {
        font-size: 2.63888889vw
    }

    [data-font-size-pc="32"] {
        font-size: 2.22222222vw
    }

    [data-font-size-pc="24"] {
        font-size: 1.66666667vw
    }

    [data-font-size-pc="22"] {
        font-size: 1.94444444vw
    }

    [data-font-size-pc="20"] {
        font-size: 1.52777778vw
    }

    [data-font-size-pc="18"] {
        font-size: 1.25vw
    }

    [data-font-size-pc="14"] {
        font-size: .97222222vw
    }
}

@media only screen and (max-width:767px) {
    .margin-top--narrow {
        padding-top: 4.44444444vw
    }

    .margin-top--normal {
        padding-top: 6.66666667vw
    }

    .margin-top--wide {
        padding-top: 8.88888889vw
    }

    .margin-bottom--narrow {
        padding-bottom: 4.44444444vw
    }

    .margin-bottom--normal {
        padding-bottom: 6.66666667vw
    }

    .margin-bottom--wide {
        padding-bottom: 8.88888889vw
    }

    [data-font-size-mo="70"] {
        font-size: 8.88888889vw
    }

    [data-font-size-mo="56"] {
        font-size: 7.22222222vw
    }

    [data-font-size-mo="44"] {
        font-size: 6.11111111vw
    }

    [data-font-size-mo="34"] {
        font-size: 4.44444444vw
    }

    [data-font-size-mo="32"] {
        font-size: 3.88888889vw
    }

    [data-font-size-mo="24"], [data-font-size-mo="28"] {
        font-size: 3.33333333vw
    }
}

@media only screen and (max-width:360px) {
    .margin-top--narrow {
        padding-top: 16px
    }

    .margin-top--normal {
        padding-top: 24px
    }

    .margin-top--wide {
        padding-top: 32px
    }

    .margin-bottom--narrow {
        padding-bottom: 16px
    }

    .margin-bottom--normal {
        padding-bottom: 24px
    }

    .margin-bottom--wide {
        padding-bottom: 32px
    }

    [data-font-size-mo="70"] {
        font-size: 32px
    }

    [data-font-size-mo="56"] {
        font-size: 26px
    }

    [data-font-size-mo="44"] {
        font-size: 22px
    }

    [data-font-size-mo="34"] {
        font-size: 16px
    }

    [data-font-size-mo="32"] {
        font-size: 14px
    }

    [data-font-size-mo="24"], [data-font-size-mo="28"] {
        font-size: 12px
    }

    .skip-bar a:focus {
        width: 360px
    }
}

@font-face {
    font-display: swap;
    font-family: SamsungOne;
    font-style: normal;
    font-weight: 400;
    src: local("SamsungOne"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-400.woff2) format("woff2"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-400.woff) format("woff"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-400.ttf) format("truetype"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-400.eot?#iefix) format("embedded-opentype")
}

@font-face {
    font-display: swap;
    font-family: SamsungOne;
    font-style: normal;
    font-weight: 700;
    src: local("SamsungOne"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-700.woff2) format("woff2"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-700.woff) format("woff"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-700.ttf) format("truetype"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-700.eot?#iefix) format("embedded-opentype")
}

@font-face {
    font-display: swap;
    font-family: SamsungSharpSans;
    font-style: normal;
    font-weight: 700;
    src: local("SamsungSharpSans"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungSharpSansBd.woff2) format("woff2"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungSharpSansBd.woff) format("woff"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungSharpSansBd.eot?#iefix) format("embedded-opentype")
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

main {
    display: block
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

pre {
    font-family: monospace;
    font-size: 1em
}

a {
    background-color: transparent
}

abbr[title] {
    border-bottom: none;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

b, strong {
    font-weight: bolder
}

code, kbd, samp {
    font-family: monospace;
    font-size: 1em
}

small {
    font-size: 80%
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

img {
    border-style: none
}

button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button, input {
    overflow: visible
}

button, select {
    text-transform: none
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button
}

    [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
        border-style: none;
        padding: 0
    }

    [type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
        outline: 1px dotted ButtonText
    }

fieldset {
    padding: .35em .75em .625em
}

legend {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}

progress {
    vertical-align: baseline
}

textarea {
    overflow: auto
}

[type=checkbox], [type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

    [type=search]::-webkit-search-decoration {
        -webkit-appearance: none
    }

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

details {
    display: block
}

summary {
    display: list-item
}

[hidden], template {
    display: none
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    border-radius: 0;
    cursor: pointer;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none
}

abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
    margin: 0;
    padding: 0;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block
}

h1, h2, h3, h4, h5, h6 {
    font-size: 100%
}

li, ol, ul {
    list-style: outside none none
}

img {
    vertical-align: top
}

fieldset, img {
    border: 0
}

a {
    text-decoration: none !important;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
}

hr {
    display: none
}

input, select, textarea {
    vertical-align: middle;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

address, em {
    font-style: normal
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

    .hidden, legend, table caption {
        display: block;
        overflow: hidden;
        position: absolute !important;
        width: 1px;
        height: 1px;
        font-size: 1px;
        line-height: 1px;
        clip: rect(1px,1px,1px,1px)
    }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #666;
    font-weight: 400
}

input:-moz-placeholder, input:-ms-input-placeholder, input::-webkit-input-placeholder, input::placeholder, textarea:-moz-placeholder, textarea:-ms-input-placeholder, textarea::-webkit-input-placeholder, textarea::placeholder {
    color: #666;
    font-weight: 400
}

.cta {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    vertical-align: middle;
    -webkit-transition-property: color,background-color,border-color,border-bottom-color;
    transition-property: color,background-color,border-color,border-bottom-color;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    -webkit-transition-timing-function: cubic-bezier(.33,0,.3,1);
    transition-timing-function: cubic-bezier(.33,0,.3,1);
    font-family: Microsoft JhengHei,SamsungOne,arial,sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    white-space: nowrap
}

.cta--contained {
    padding: 10px 24px 11px;
    border-radius: 20px
}

    .cta--contained.cta--black {
        color: #fff !important;
        background-color: #000 !important
    }

        .cta--contained.cta--black:focus, .cta--contained.cta--black:hover {
            color: #eee !important;
            background-color: #555 !important
        }

        .cta--contained.cta--black.cta--disabled {
            cursor: default !important
        }

            .cta--contained.cta--black.cta--disabled, .cta--contained.cta--black.cta--disabled:focus, .cta--contained.cta--black.cta--disabled:hover {
                color: #eee !important;
                background-color: #ddd !important
            }

        .cta--contained.cta--black[target=_blank]:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-white.svg) !important
        }

        .cta--contained.cta--black[target=_blank].cta--disabled:after, .cta--contained.cta--black[target=_blank]:focus:after, .cta--contained.cta--black[target=_blank]:hover:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-white-dim.svg) !important
        }

    .cta--contained.cta--white {
        color: #000 !important;
        background-color: #fff !important
    }

        .cta--contained.cta--white:focus, .cta--contained.cta--white:hover {
            color: #313131 !important;
            background-color: #ddd !important
        }

        .cta--contained.cta--white.cta--disabled {
            cursor: default !important
        }

            .cta--contained.cta--white.cta--disabled, .cta--contained.cta--white.cta--disabled:focus, .cta--contained.cta--white.cta--disabled:hover {
                color: #313131 !important;
                background-color: #555 !important
            }

        .cta--contained.cta--white[target=_blank]:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-black.svg) !important
        }

        .cta--contained.cta--white[target=_blank].cta--disabled:after, .cta--contained.cta--white[target=_blank]:focus:after, .cta--contained.cta--white[target=_blank]:hover:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-black-dim.svg) !important
        }

    .cta--contained.cta--emphasis {
        color: #fff !important;
        background-color: #2189ff !important
    }

        .cta--contained.cta--emphasis:focus, .cta--contained.cta--emphasis:hover {
            color: #f4f4f4 !important;
            background-color: #3ba7ff !important
        }

        .cta--contained.cta--emphasis.cta--disabled {
            cursor: default !important
        }

            .cta--contained.cta--emphasis.cta--disabled, .cta--contained.cta--emphasis.cta--disabled:focus, .cta--contained.cta--emphasis.cta--disabled:hover {
                color: #f4f4f4 !important;
                background-color: #d4e2f3 !important
            }

        .cta--contained.cta--emphasis[target=_blank]:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-white.svg) !important
        }

        .cta--contained.cta--emphasis[target=_blank].cta--disabled:after, .cta--contained.cta--emphasis[target=_blank]:focus:after, .cta--contained.cta--emphasis[target=_blank]:hover:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-white-emphasis.svg) !important
        }

.cta--outlined {
    padding: 9px 23px 10px;
    border-radius: 20px;
    border-width: 1px;
    border-style: solid
}

    .cta--outlined.cta--black {
        color: #000 !important;
        background-color: transparent !important;
        border-color: #000 !important
    }

        .cta--outlined.cta--black:focus, .cta--outlined.cta--black:hover {
            color: #555 !important;
            background-color: rgba(0,0,0,.05) !important;
            border-color: #555 !important
        }

        .cta--outlined.cta--black.cta--disabled {
            color: #ddd !important;
            border-color: #ddd !important;
            cursor: default !important
        }

            .cta--outlined.cta--black.cta--disabled:focus, .cta--outlined.cta--black.cta--disabled:hover {
                color: #ddd !important;
                background-color: transparent !important;
                border-color: #ddd !important
            }

        .cta--outlined.cta--black[target=_blank]:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-black.svg) !important
        }

        .cta--outlined.cta--black[target=_blank]:focus:after, .cta--outlined.cta--black[target=_blank]:hover:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray.svg) !important
        }

        .cta--outlined.cta--black[target=_blank].cta--disabled:after, .cta--outlined.cta--black[target=_blank].cta--disabled:focus:after, .cta--outlined.cta--black[target=_blank].cta--disabled:hover:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray-dim.svg) !important
        }

    .cta--outlined.cta--white {
        color: #fff !important;
        background-color: transparent !important;
        border-color: #fff !important
    }

        .cta--outlined.cta--white:focus, .cta--outlined.cta--white:hover {
            color: #ddd !important;
            background-color: hsla(0,0%,100%,.15) !important;
            border-color: #ddd !important
        }

        .cta--outlined.cta--white.cta--disabled {
            color: #555 !important;
            border-color: #555 !important;
            cursor: default !important
        }

            .cta--outlined.cta--white.cta--disabled:focus, .cta--outlined.cta--white.cta--disabled:hover {
                color: #555 !important;
                background-color: transparent !important;
                border-color: #555 !important
            }

        .cta--outlined.cta--white[target=_blank]:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-white.svg) !important
        }

        .cta--outlined.cta--white[target=_blank]:focus:after, .cta--outlined.cta--white[target=_blank]:hover:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray-dim.svg) !important
        }

        .cta--outlined.cta--white[target=_blank].cta--disabled:after, .cta--outlined.cta--white[target=_blank].cta--disabled:focus:after, .cta--outlined.cta--white[target=_blank].cta--disabled:hover:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray.svg) !important
        }

.cta--underline {
    padding: 10px 0 11px
}

    .cta--underline:before {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 8px;
        height: 2px;
        background-color: currentColor
    }

    .cta--underline:focus:before, .cta--underline:hover:before {
        -webkit-animation: cta-underline-animation .2s both;
        animation: cta-underline-animation .2s both;
        -webkit-animation-timing-function: cubic-bezier(.4,0,.2,1);
        animation-timing-function: cubic-bezier(.4,0,.2,1);
        -webkit-animation-delay: .1s;
        animation-delay: .1s
    }

    .cta--underline.cta--black {
        color: #000 !important
    }

        .cta--underline.cta--black:focus, .cta--underline.cta--black:hover {
            color: #555 !important
        }

        .cta--underline.cta--black.cta--disabled {
            cursor: default !important
        }

            .cta--underline.cta--black.cta--disabled, .cta--underline.cta--black.cta--disabled:focus, .cta--underline.cta--black.cta--disabled:hover {
                color: #ddd !important;
                border-color: #ddd !important
            }

        .cta--underline.cta--black[target=_blank]:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-black.svg) !important
        }

        .cta--underline.cta--black[target=_blank]:focus:after, .cta--underline.cta--black[target=_blank]:hover:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray.svg) !important
        }

        .cta--underline.cta--black[target=_blank].cta--disabled:after, .cta--underline.cta--black[target=_blank].cta--disabled:focus:after, .cta--underline.cta--black[target=_blank].cta--disabled:hover:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray-dim.svg) !important
        }

    .cta--underline.cta--white {
        color: #fff !important
    }

        .cta--underline.cta--white:focus, .cta--underline.cta--white:hover {
            color: #ddd !important
        }

        .cta--underline.cta--white.cta--disabled {
            cursor: default !important
        }

            .cta--underline.cta--white.cta--disabled, .cta--underline.cta--white.cta--disabled:focus, .cta--underline.cta--white.cta--disabled:hover {
                color: #555 !important;
                border-color: #555 !important
            }

        .cta--underline.cta--white[target=_blank]:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-white.svg) !important
        }

        .cta--underline.cta--white[target=_blank]:focus:after, .cta--underline.cta--white[target=_blank]:hover:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray-dim.svg) !important
        }

        .cta--underline.cta--white[target=_blank].cta--disabled:after, .cta--underline.cta--white[target=_blank].cta--disabled:focus:after, .cta--underline.cta--white[target=_blank].cta--disabled:hover:after {
            background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray.svg) !important
        }

.cta--label.cta--black {
    color: #000 !important
}

    .cta--label.cta--black:focus, .cta--label.cta--black:hover {
        color: #555 !important
    }

    .cta--label.cta--black.cta--disabled {
        color: #ddd !important;
        cursor: default !important
    }

        .cta--label.cta--black.cta--disabled:focus, .cta--label.cta--black.cta--disabled:hover {
            color: #ddd !important
        }

.cta--label.cta--white {
    color: #fff !important
}

    .cta--label.cta--white:focus, .cta--label.cta--white:hover {
        color: #ddd !important
    }

    .cta--label.cta--white.cta--disabled {
        color: #555 !important;
        cursor: default !important
    }

        .cta--label.cta--white.cta--disabled:focus, .cta--label.cta--white.cta--disabled:hover {
            color: #555 !important
        }

.cta[target=_blank]:after {
    content: " ";
    position: absolute;
    width: 16px;
    height: 16px;
    right: 24px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-size: 100% 100%
}

.cta[target=_blank].cta--contained {
    padding-right: 44px
}

.cta[target=_blank].cta--outlined {
    padding-right: 43px
}

.cta[target=_blank].cta--underline {
    padding-right: 20px
}

    .cta[target=_blank].cta--underline[target=_blank]:after {
        right: 0
    }

.cta.cta--icon .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    fill: currentColor;
    -webkit-transition: fill .2s cubic-bezier(.33,0,.3,1);
    transition: fill .2s cubic-bezier(.33,0,.3,1)
}

.cta.cta--icon-leading .icon {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    margin-left: 0;
    margin-right: 4px
}

.cta.cta--icon:after {
    display: none
}

.cta.cta--icon.cta--contained {
    padding-right: 24px
}

.cta.cta--icon.cta--outlined {
    padding-right: 23px
}

.cta.cta--icon.cta--underline {
    padding-right: 0
}

.cta--disabled {
    pointer-events: none
}

    .cta--disabled.cta--underline:focus:before, .cta--disabled.cta--underline:hover:before {
        -webkit-animation: none;
        animation: none
    }

.bg-black .cta--contained, .bg-dark-gray .cta--contained {
    color: #000;
    background-color: #fff
}

    .bg-black .cta--contained:focus, .bg-black .cta--contained:hover, .bg-dark-gray .cta--contained:focus, .bg-dark-gray .cta--contained:hover {
        color: #313131;
        background-color: #ddd
    }

    .bg-black .cta--contained.cta--disabled, .bg-dark-gray .cta--contained.cta--disabled {
        color: #313131;
        background-color: #555;
        cursor: default
    }

        .bg-black .cta--contained.cta--disabled:focus, .bg-black .cta--contained.cta--disabled:hover, .bg-dark-gray .cta--contained.cta--disabled:focus, .bg-dark-gray .cta--contained.cta--disabled:hover {
            color: #313131;
            background-color: #555
        }

    .bg-black .cta--contained[target=_blank]:after, .bg-dark-gray .cta--contained[target=_blank]:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-black.svg)
    }

    .bg-black .cta--contained[target=_blank].cta--disabled:after, .bg-black .cta--contained[target=_blank]:focus:after, .bg-black .cta--contained[target=_blank]:hover:after, .bg-dark-gray .cta--contained[target=_blank].cta--disabled:after, .bg-dark-gray .cta--contained[target=_blank]:focus:after, .bg-dark-gray .cta--contained[target=_blank]:hover:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-black-dim.svg)
    }

.bg-black .cta--outlined, .bg-dark-gray .cta--outlined {
    color: #fff;
    background-color: transparent;
    border-color: #fff
}

    .bg-black .cta--outlined:focus, .bg-black .cta--outlined:hover, .bg-dark-gray .cta--outlined:focus, .bg-dark-gray .cta--outlined:hover {
        color: #ddd;
        background-color: hsla(0,0%,100%,.15);
        border-color: #ddd
    }

    .bg-black .cta--outlined.cta--disabled, .bg-dark-gray .cta--outlined.cta--disabled {
        color: #555;
        border-color: #555;
        cursor: default
    }

        .bg-black .cta--outlined.cta--disabled:focus, .bg-black .cta--outlined.cta--disabled:hover, .bg-dark-gray .cta--outlined.cta--disabled:focus, .bg-dark-gray .cta--outlined.cta--disabled:hover {
            color: #555;
            background-color: transparent;
            border-color: #555
        }

    .bg-black .cta--outlined[target=_blank]:after, .bg-dark-gray .cta--outlined[target=_blank]:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-white.svg)
    }

    .bg-black .cta--outlined[target=_blank]:focus:after, .bg-black .cta--outlined[target=_blank]:hover:after, .bg-dark-gray .cta--outlined[target=_blank]:focus:after, .bg-dark-gray .cta--outlined[target=_blank]:hover:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray-dim.svg)
    }

    .bg-black .cta--outlined[target=_blank].cta--disabled:after, .bg-black .cta--outlined[target=_blank].cta--disabled:focus:after, .bg-black .cta--outlined[target=_blank].cta--disabled:hover:after, .bg-dark-gray .cta--outlined[target=_blank].cta--disabled:after, .bg-dark-gray .cta--outlined[target=_blank].cta--disabled:focus:after, .bg-dark-gray .cta--outlined[target=_blank].cta--disabled:hover:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray.svg)
    }

.bg-black .cta--underline, .bg-dark-gray .cta--underline {
    color: #fff
}

    .bg-black .cta--underline:focus, .bg-black .cta--underline:hover, .bg-dark-gray .cta--underline:focus, .bg-dark-gray .cta--underline:hover {
        color: #ddd
    }

    .bg-black .cta--underline.cta--disabled, .bg-dark-gray .cta--underline.cta--disabled {
        color: #555;
        border-color: #555;
        cursor: default
    }

        .bg-black .cta--underline.cta--disabled:focus, .bg-black .cta--underline.cta--disabled:hover, .bg-dark-gray .cta--underline.cta--disabled:focus, .bg-dark-gray .cta--underline.cta--disabled:hover {
            color: #555;
            border-color: #555
        }

    .bg-black .cta--underline[target=_blank]:after, .bg-dark-gray .cta--underline[target=_blank]:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-white.svg)
    }

    .bg-black .cta--underline[target=_blank]:focus:after, .bg-black .cta--underline[target=_blank]:hover:after, .bg-dark-gray .cta--underline[target=_blank]:focus:after, .bg-dark-gray .cta--underline[target=_blank]:hover:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray-dim.svg)
    }

    .bg-black .cta--underline[target=_blank].cta--disabled:after, .bg-black .cta--underline[target=_blank].cta--disabled:focus:after, .bg-black .cta--underline[target=_blank].cta--disabled:hover:after, .bg-dark-gray .cta--underline[target=_blank].cta--disabled:after, .bg-dark-gray .cta--underline[target=_blank].cta--disabled:focus:after, .bg-dark-gray .cta--underline[target=_blank].cta--disabled:hover:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray.svg)
    }

.bg-black .cta--label, .bg-dark-gray .cta--label {
    color: #fff
}

    .bg-black .cta--label:focus, .bg-black .cta--label:hover, .bg-dark-gray .cta--label:focus, .bg-dark-gray .cta--label:hover {
        color: #ddd
    }

    .bg-black .cta--label.cta--disabled, .bg-dark-gray .cta--label.cta--disabled {
        color: #555;
        cursor: default
    }

        .bg-black .cta--label.cta--disabled:focus, .bg-black .cta--label.cta--disabled:hover, .bg-dark-gray .cta--label.cta--disabled:focus, .bg-dark-gray .cta--label.cta--disabled:hover {
            color: #555
        }

.bg-light-gray .cta--contained, .bg-transparent .cta--contained, .bg-white .cta--contained {
    color: #fff;
    background-color: #000
}

    .bg-light-gray .cta--contained:focus, .bg-light-gray .cta--contained:hover, .bg-transparent .cta--contained:focus, .bg-transparent .cta--contained:hover, .bg-white .cta--contained:focus, .bg-white .cta--contained:hover {
        color: #eee;
        background-color: #555
    }

    .bg-light-gray .cta--contained.cta--disabled, .bg-transparent .cta--contained.cta--disabled, .bg-white .cta--contained.cta--disabled {
        color: #eee;
        background-color: #ddd;
        cursor: default
    }

        .bg-light-gray .cta--contained.cta--disabled:focus, .bg-light-gray .cta--contained.cta--disabled:hover, .bg-transparent .cta--contained.cta--disabled:focus, .bg-transparent .cta--contained.cta--disabled:hover, .bg-white .cta--contained.cta--disabled:focus, .bg-white .cta--contained.cta--disabled:hover {
            color: #eee;
            background-color: #ddd
        }

    .bg-light-gray .cta--contained[target=_blank]:after, .bg-transparent .cta--contained[target=_blank]:after, .bg-white .cta--contained[target=_blank]:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-white.svg)
    }

    .bg-light-gray .cta--contained[target=_blank].cta--disabled:after, .bg-light-gray .cta--contained[target=_blank]:focus:after, .bg-light-gray .cta--contained[target=_blank]:hover:after, .bg-transparent .cta--contained[target=_blank].cta--disabled:after, .bg-transparent .cta--contained[target=_blank]:focus:after, .bg-transparent .cta--contained[target=_blank]:hover:after, .bg-white .cta--contained[target=_blank].cta--disabled:after, .bg-white .cta--contained[target=_blank]:focus:after, .bg-white .cta--contained[target=_blank]:hover:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-white-dim.svg)
    }

.bg-light-gray .cta--outlined, .bg-transparent .cta--outlined, .bg-white .cta--outlined {
    color: #000;
    background-color: transparent;
    border-color: #000
}

    .bg-light-gray .cta--outlined:focus, .bg-light-gray .cta--outlined:hover, .bg-transparent .cta--outlined:focus, .bg-transparent .cta--outlined:hover, .bg-white .cta--outlined:focus, .bg-white .cta--outlined:hover {
        color: #555;
        background-color: rgba(0,0,0,.05);
        border-color: #555
    }

    .bg-light-gray .cta--outlined.cta--disabled, .bg-transparent .cta--outlined.cta--disabled, .bg-white .cta--outlined.cta--disabled {
        color: #ddd;
        border-color: #ddd;
        cursor: default
    }

        .bg-light-gray .cta--outlined.cta--disabled:focus, .bg-light-gray .cta--outlined.cta--disabled:hover, .bg-transparent .cta--outlined.cta--disabled:focus, .bg-transparent .cta--outlined.cta--disabled:hover, .bg-white .cta--outlined.cta--disabled:focus, .bg-white .cta--outlined.cta--disabled:hover {
            color: #ddd;
            background-color: transparent;
            border-color: #ddd
        }

    .bg-light-gray .cta--outlined[target=_blank]:after, .bg-transparent .cta--outlined[target=_blank]:after, .bg-white .cta--outlined[target=_blank]:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-black.svg)
    }

    .bg-light-gray .cta--outlined[target=_blank]:focus:after, .bg-light-gray .cta--outlined[target=_blank]:hover:after, .bg-transparent .cta--outlined[target=_blank]:focus:after, .bg-transparent .cta--outlined[target=_blank]:hover:after, .bg-white .cta--outlined[target=_blank]:focus:after, .bg-white .cta--outlined[target=_blank]:hover:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray.svg)
    }

    .bg-light-gray .cta--outlined[target=_blank].cta--disabled:after, .bg-light-gray .cta--outlined[target=_blank].cta--disabled:focus:after, .bg-light-gray .cta--outlined[target=_blank].cta--disabled:hover:after, .bg-transparent .cta--outlined[target=_blank].cta--disabled:after, .bg-transparent .cta--outlined[target=_blank].cta--disabled:focus:after, .bg-transparent .cta--outlined[target=_blank].cta--disabled:hover:after, .bg-white .cta--outlined[target=_blank].cta--disabled:after, .bg-white .cta--outlined[target=_blank].cta--disabled:focus:after, .bg-white .cta--outlined[target=_blank].cta--disabled:hover:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray-dim.svg)
    }

.bg-light-gray .cta--underline, .bg-transparent .cta--underline, .bg-white .cta--underline {
    color: #000
}

    .bg-light-gray .cta--underline:focus, .bg-light-gray .cta--underline:hover, .bg-transparent .cta--underline:focus, .bg-transparent .cta--underline:hover, .bg-white .cta--underline:focus, .bg-white .cta--underline:hover {
        color: #555
    }

    .bg-light-gray .cta--underline.cta--disabled, .bg-transparent .cta--underline.cta--disabled, .bg-white .cta--underline.cta--disabled {
        color: #ddd;
        border-color: #ddd;
        cursor: default
    }

        .bg-light-gray .cta--underline.cta--disabled:focus, .bg-light-gray .cta--underline.cta--disabled:hover, .bg-transparent .cta--underline.cta--disabled:focus, .bg-transparent .cta--underline.cta--disabled:hover, .bg-white .cta--underline.cta--disabled:focus, .bg-white .cta--underline.cta--disabled:hover {
            color: #ddd;
            border-color: #ddd
        }

    .bg-light-gray .cta--underline[target=_blank]:after, .bg-transparent .cta--underline[target=_blank]:after, .bg-white .cta--underline[target=_blank]:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-black.svg)
    }

    .bg-light-gray .cta--underline[target=_blank]:focus:after, .bg-light-gray .cta--underline[target=_blank]:hover:after, .bg-transparent .cta--underline[target=_blank]:focus:after, .bg-transparent .cta--underline[target=_blank]:hover:after, .bg-white .cta--underline[target=_blank]:focus:after, .bg-white .cta--underline[target=_blank]:hover:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray.svg)
    }

    .bg-light-gray .cta--underline[target=_blank].cta--disabled:after, .bg-light-gray .cta--underline[target=_blank].cta--disabled:focus:after, .bg-light-gray .cta--underline[target=_blank].cta--disabled:hover:after, .bg-transparent .cta--underline[target=_blank].cta--disabled:after, .bg-transparent .cta--underline[target=_blank].cta--disabled:focus:after, .bg-transparent .cta--underline[target=_blank].cta--disabled:hover:after, .bg-white .cta--underline[target=_blank].cta--disabled:after, .bg-white .cta--underline[target=_blank].cta--disabled:focus:after, .bg-white .cta--underline[target=_blank].cta--disabled:hover:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-gray-dim.svg)
    }

.bg-light-gray .cta--label, .bg-transparent .cta--label, .bg-white .cta--label {
    color: #000
}

    .bg-light-gray .cta--label:focus, .bg-light-gray .cta--label:hover, .bg-transparent .cta--label:focus, .bg-transparent .cta--label:hover, .bg-white .cta--label:focus, .bg-white .cta--label:hover {
        color: #555
    }

    .bg-light-gray .cta--label.cta--disabled, .bg-transparent .cta--label.cta--disabled, .bg-white .cta--label.cta--disabled {
        color: #ddd;
        cursor: default
    }

        .bg-light-gray .cta--label.cta--disabled:focus, .bg-light-gray .cta--label.cta--disabled:hover, .bg-transparent .cta--label.cta--disabled:focus, .bg-transparent .cta--label.cta--disabled:hover, .bg-white .cta--label.cta--disabled:focus, .bg-white .cta--label.cta--disabled:hover {
            color: #ddd
        }

@media only screen and (min-width:768px) and (max-width:1440px) {
    .cta {
        font-family: Microsoft JhengHei,SamsungOne,arial,sans-serif;
        font-weight: 700;
        font-size: .97222222vw;
        line-height: 1.31944444vw
    }

    .cta--contained {
        padding: .69444444vw 1.66666667vw .76388889vw;
        border-radius: 1.38888889vw
    }

    .cta--outlined {
        padding: .625vw 1.59722222vw .69444444vw;
        border-radius: 1.38888889vw;
        border-width: .06944444vw
    }

    .cta--underline {
        padding: .69444444vw 0 .76388889vw
    }

        .cta--underline:before {
            bottom: .55555556vw;
            height: .13888889vw
        }

    .cta[target=_blank]:after {
        width: 1.11111111vw;
        height: 1.11111111vw;
        right: 1.66666667vw
    }

    .cta[target=_blank].cta--contained {
        padding-right: 3.05555556vw
    }

    .cta[target=_blank].cta--outlined {
        padding-right: 2.98611111vw
    }

    .cta[target=_blank].cta--underline {
        padding-right: 1.38888889vw
    }

    .cta.cta--icon .icon {
        width: 1.11111111vw;
        height: 1.11111111vw;
        margin-left: .27777778vw
    }

    .cta.cta--icon-leading .icon {
        margin-left: 0;
        margin-right: .27777778vw
    }

    .cta.cta--icon.cta--contained {
        padding-right: 1.66666667vw
    }

    .cta.cta--icon.cta--outlined {
        padding-right: 1.59722222vw
    }

    .cta.cta--icon.cta--underline {
        padding-right: 0
    }
}

@media only screen and (max-width:767px) {
    .cta {
        font-family: Microsoft JhengHei,SamsungOne,arial,sans-serif;
        font-weight: 700;
        font-size: 3.88888889vw;
        line-height: 5.27777778vw
    }

    .cta--contained {
        padding: 2.77777778vw 6.66666667vw 3.05555556vw;
        border-radius: 5.55555556vw
    }

    .cta--outlined {
        padding: 2.5vw 6.38888889vw 2.77777778vw;
        border-radius: 5.55555556vw;
        border-width: .27777778vw
    }

    .cta--underline {
        padding: 2.77777778vw 0 3.05555556vw
    }

        .cta--underline:before {
            bottom: 2.22222222vw;
            height: .55555556vw
        }

    .cta[target=_blank]:after {
        width: 4.44444444vw;
        height: 4.44444444vw;
        right: 6.66666667vw
    }

    .cta[target=_blank].cta--contained {
        padding-right: 12.22222222vw
    }

    .cta[target=_blank].cta--outlined {
        padding-right: 11.94444444vw
    }

    .cta[target=_blank].cta--underline {
        padding-right: 5.55555556vw
    }

    .cta.cta--icon .icon {
        width: 4.44444444vw;
        height: 4.44444444vw;
        margin-left: 1.11111111vw
    }

    .cta.cta--icon-leading .icon {
        margin-left: 0;
        margin-right: 1.11111111vw
    }

    .cta.cta--icon.cta--contained {
        padding-right: 6.66666667vw
    }

    .cta.cta--icon.cta--outlined {
        padding-right: 6.38888889vw
    }

    .cta.cta--icon.cta--underline {
        padding-right: 0
    }
}

@-webkit-keyframes cta-underline-animation {
    0% {
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0)
    }

    to {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}

@keyframes cta-underline-animation {
    0% {
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0)
    }

    to {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}

.image {
    position: relative
}

.image, .image__main, .image__preview {
    display: inline-block;
    width: 100%
}

    .image .hover-scale {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: -webkit-transform .3s linear;
        transition: -webkit-transform .3s linear;
        transition: transform .3s linear;
        transition: transform .3s linear,-webkit-transform .3s linear
    }

        .image .hover-scale:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1)
        }

    .image__preview + .image__main {
        position: absolute;
        left: 0;
        top: 0
    }

    .image .hover-image {
        opacity: 1;
        visibility: visible;
        -webkit-transition: visibility 0s,opacity .1s cubic-bezier(.39,.575,.565,1) .1s;
        transition: visibility 0s,opacity .1s cubic-bezier(.39,.575,.565,1) .1s
    }

.image__hover-image--hover {
    position: absolute;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    left: 0;
    -webkit-transition: visibility 0s .2s,opacity .2s cubic-bezier(.47,0,.745,.715);
    transition: visibility 0s .2s,opacity .2s cubic-bezier(.47,0,.745,.715)
}

.image:hover .hover-image {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s 1s,opacity .2s cubic-bezier(.47,0,.745,.715);
    transition: visibility 0s 1s,opacity .2s cubic-bezier(.47,0,.745,.715)
}

.image:hover .image__hover-image--hover {
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s,opacity .1s cubic-bezier(.39,.575,.565,1) .1s;
    transition: visibility 0s,opacity .1s cubic-bezier(.39,.575,.565,1) .1s
}

.image .lazy-load {
    visibility: hidden
}

.image-360 {
    width: 414px;
    height: 414px;
    overflow: hidden
}

    .image-360 [data-akamai-viewer] div {
        height: 100%
    }

    .image-360 [data-akamai-viewer] canvas {
        display: block;
        max-width: 100%;
        height: 100%;
        margin: 0 auto
    }

.gnb {
    position: relative;
    z-index: 2000
}

.gnb__dimmed {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0;
    z-index: 2000;
    -webkit-transition: opacity .6s cubic-bezier(.4,0,.2,1) .2s;
    transition: opacity .6s cubic-bezier(.4,0,.2,1) .2s
}

    .gnb__dimmed.open {
        display: block;
        opacity: .68
    }

.gnb__nav {
    position: relative;
    background: #fff;
    z-index: 2000
}

.gnb__bar {
    max-width: 1440px;
    height: 80px;
    margin: 0 auto;
    background: #fff;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

.gnb__bar-inner {
    padding: 0 24px 0 16px;
    font-size: 0
}

    .gnb__bar-inner:after {
        content: "";
        display: block;
        clear: both
    }

.gnb__logo {
    float: left;
    display: table-cell;
    position: relative;
    width: 120px;
    height: 80px;
    vertical-align: middle
}

    .gnb__logo:after {
        content: "";
        display: block;
        position: absolute;
        left: 8px;
        top: 50%;
        right: 8px;
        height: 16px;
        margin-top: -8px;
        background: url(https://twsamsungcampaign.azureedge.net/common_api/images/global-samsung-logo.svg) no-repeat 50% 50%;
        background-size: 100% auto
    }

.gnb__main {
    float: left;
    margin-left: 64px;
    font-size: 0
}

.gnb__depth1 {
    display: table;
    line-height: 1.33
}

.gnb__depth1-menu {
    display: table-cell;
    height: 80px;
    vertical-align: middle
}

.gnb__depth1-menu--only-mobile {
    display: none
}

    .gnb__depth1-menu--only-mobile + .gnb__depth1-menu {
        margin-left: 0
    }

.gnb__depth1-link {
    display: inline-block;
    overflow: hidden;
    position: relative;
    padding: 6px 12px;
    border-radius: 20px;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.33
}

    .gnb__depth1-link .icon {
        display: none
    }

    .gnb__depth1-link.active {
        background: #000;
        color: #fff
    }

.gnb__depth2-wrap {
    overflow: hidden;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    background: #fff;
    -webkit-transition: top .6s cubic-bezier(.6,0,.4,1);
    transition: top .6s cubic-bezier(.6,0,.4,1)
}

.gnb__depth-back-wrap {
    display: none
}

.gnb__depth2-inner {
    visibility: hidden;
    overflow: hidden;
    position: relative;
    max-width: 1440px;
    height: 0;
    margin: 0 auto;
    padding: 0 24px;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

    .gnb__depth2-inner.active {
        visibility: visible;
        padding: 0 24px;
        z-index: 2100
    }

.gnb__depth2 {
    position: relative;
    width: 260px;
    padding: 8px 0;
    opacity: 0;
    -webkit-transition: opacity .3s cubic-bezier(.5,.1,.7,.2) .1s;
    transition: opacity .3s cubic-bezier(.5,.1,.7,.2) .1s
}

    .gnb__depth2.active {
        opacity: 1
    }

.gnb__depth2-title-wrap {
    display: table;
    width: 100%;
    opacity: 0;
    padding-top: 8px;
    margin-top: -30px;
    -webkit-transition: all .5s cubic-bezier(.4,0,.2,1) .1s;
    transition: all .5s cubic-bezier(.4,0,.2,1) .1s
}

.gnb__depth2-title {
    display: table-cell;
    height: 80px;
    color: #000;
    font-size: 32px;
    line-height: 1.33;
    vertical-align: middle
}

.gnb__depth2-title-link {
    display: inline-block;
    position: relative;
    color: #000
}

    .gnb__depth2-title-link:after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 100%;
        width: 0;
        margin-top: 2px;
        border-bottom: 2px solid #000;
        -webkit-transition: all .2s cubic-bezier(.4,0,.2,1);
        transition: all .2s cubic-bezier(.4,0,.2,1)
    }

    .gnb__depth2-title-link:focus:after, .gnb__depth2-title-link:hover:after {
        left: 0;
        width: 100%
    }

.gnb__depth2-menu {
    display: table;
    width: 100%
}

    .gnb__depth2-menu + .gnb__depth2-menu {
        margin-top: 16px
    }

.gnb__depth2-link {
    display: table-cell;
    position: relative;
    padding: 8px 32px 8px 0;
    color: #000;
    font-size: 18px;
    vertical-align: middle
}

.gnb__depth2-link-text {
    display: inline-block;
    position: relative
}

    .gnb__depth2-link-text:before {
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 1px;
        margin-top: 2px;
        background: #000;
        -webkit-transition: all .2s cubic-bezier(.4,0,.2,1);
        transition: all .2s cubic-bezier(.4,0,.2,1)
    }

.gnb__depth2-link .icon {
    display: none
}

.gnb__depth2-link:focus .gnb__depth2-link-text:before, .gnb__depth2-link:hover .gnb__depth2-link-text:before {
    left: 0;
    width: 100%
}

.gnb__depth2-menu.active .gnb__depth2-link {
    font-weight: 700
}

.gnb__depth2-link[target=_blank] .gnb__depth2-link-text .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle
}

.gnb__depth2-dropdown-cta {
    display: none
}

.gnb__depth2-close {
    visibility: hidden;
    position: absolute;
    right: 12px;
    top: 48px;
    width: 48px;
    height: 48px;
    margin-top: -24px;
    z-index: 2100;
    opacity: 0;
    -webkit-transition: all 1s cubic-bezier(.4,0,.2,1) .1s;
    transition: all 1s cubic-bezier(.4,0,.2,1) .1s
}

    .gnb__depth2-close svg.icon {
        position: absolute;
        left: 12px;
        top: 12px;
        width: 24px;
        height: 24px
    }

.gnb__depth1-menu.active .gnb__depth2-title-wrap {
    opacity: 1;
    margin-top: 0
}

.gnb__depth3-wrap {
    visibility: hidden;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 328px;
    right: 0;
    width: 424px;
    opacity: 0
}

.gnb__depth2-menu.active .gnb__depth3-wrap {
    visibility: visible;
    opacity: 1;
    left: 378px;
    -webkit-transition: all .5s cubic-bezier(.6,0,.4,1) .4s;
    transition: all .5s cubic-bezier(.6,0,.4,1) .4s
}

.gnb__depth3 {
    overflow: hidden;
    padding: 8px 0;
    font-size: 0
}

.gnb__depth3-menu ~ .gnb__depth3-menu {
    margin-top: 16px
}

.gnb__depth3-link {
    display: inline-block;
    padding: 8px 0;
    color: #000;
    font-size: 18px
}

.gnb__depth3-link-text {
    display: inline-block;
    position: relative
}

    .gnb__depth3-link-text:before {
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 1px;
        margin-top: 2px;
        background: #000;
        -webkit-transition: all .2s cubic-bezier(.4,0,.2,1);
        transition: all .2s cubic-bezier(.4,0,.2,1)
    }

.gnb__depth3-link:focus .gnb__depth3-link-text, .gnb__depth3-link:hover .gnb__depth3-link-text {
    font-weight: 700
}

    .gnb__depth3-link:focus .gnb__depth3-link-text:before, .gnb__depth3-link:hover .gnb__depth3-link-text:before {
        left: 0;
        width: 100%
    }

.gnb__feature-container {
    position: absolute;
    right: 0;
    top: 58px;
    width: 497px;
    padding: 0 83px;
    border-left: 1px solid #ddd;
    opacity: 0;
    -webkit-transition: all .4s cubic-bezier(.4,0,.2,1) .3s;
    transition: all .4s cubic-bezier(.4,0,.2,1) .3s
}

.gnb__feature-container-link {
    display: block
}

.gnb__feature-container .image {
    overflow: hidden;
    width: 330px;
    height: 330px;
    border-radius: 20px
}

    .gnb__feature-container .image img {
        -webkit-transition: -webkit-transform .2s cubic-bezier(.4,0,.2,1);
        transition: -webkit-transform .2s cubic-bezier(.4,0,.2,1);
        transition: transform .2s cubic-bezier(.4,0,.2,1);
        transition: transform .2s cubic-bezier(.4,0,.2,1),-webkit-transform .2s cubic-bezier(.4,0,.2,1)
    }

    .gnb__feature-container .image:hover img {
        -webkit-transform: scale(1.05);
        transform: scale(1.05)
    }

.gnb__feature-container-contents {
    padding: 24px 8px;
    color: #000;
    font-weight: 700;
    font-size: 18px
}

    .gnb__feature-container-contents .cta {
        margin: 6px 0 -8px
    }

.gnb__depth1-menu.active .gnb__feature-container, .gnb__depth2-wrap.open + .gnb__feature-container {
    opacity: 1;
    top: 88px
}

.gnb__sub {
    float: right;
    font-size: 0
}

    .gnb__sub .gnb__depth1 {
        float: left
    }

.gnb__utility {
    float: right;
    margin: 28px 0 0 36px
}

    .gnb__utility > li {
        display: inline-block;
        position: relative
    }

        .gnb__utility > li + li {
            margin-left: 24px
        }

        .gnb__utility > li > a {
            display: block
        }

            .gnb__utility > li > a, .gnb__utility > li > a svg.icon {
                width: 24px;
                height: 24px
            }

    .gnb__utility .gnb__login-layer {
        visibility: hidden;
        position: absolute;
        top: 36px;
        right: -24px;
        width: 210px;
        padding: 26px 24px;
        background: #fff;
        border-radius: 20px;
        -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2);
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.2);
        z-index: 3000;
        opacity: 0;
        -webkit-transform: scale(.6);
        transform: scale(.6);
        -webkit-transition: all .1s cubic-bezier(.2,.6,.4,1);
        transition: all .1s cubic-bezier(.2,.6,.4,1);
        -webkit-transform-origin: 100% 0;
        transform-origin: 100% 0
    }

        .gnb__utility .gnb__login-layer.active {
            visibility: visible;
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1)
        }

        .gnb__utility .gnb__login-layer .gnb__utility-menu li + li {
            margin-top: 20px
        }

        .gnb__utility .gnb__login-layer .gnb__user-name, .gnb__utility .gnb__login-layer .gnb__utility-link {
            display: block;
            color: #000;
            font-size: 14px;
            line-height: 1.33
        }

        .gnb__utility .gnb__login-layer .gnb__user-name {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #ddd
        }

    .gnb__utility .gnb__cart {
        position: relative
    }

    .gnb__utility .gnb__cart-in-number {
        position: absolute;
        top: -3px;
        left: 15px;
        z-index: 2000;
        min-width: 12px;
        height: 12px;
        padding: 0 2px;
        border-radius: 6px;
        background-color: #d93b30;
        color: #fff;
        font-size: 10px;
        font-weight: 700;
        text-align: center;
        line-height: 14px;
        letter-spacing: normal;
        text-indent: 1px
    }

.gnb .gnb__depth3-link[target=_blank] .gnb__depth3-link-text .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle
}

.gnb .gnb__depth2-menu.has-depth-menu.active .gnb__depth2-link .icon, .gnb .gnb__depth2-menu.has-depth-menu .gnb__depth2-link:focus .icon, .gnb .gnb__depth2-menu.has-depth-menu .gnb__depth2-link:hover .icon {
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    width: 24px;
    height: 24px;
    margin-top: -12px
}

.gnb__depth-title, .gnb__menu-close, .gnb__mobile-menu {
    display: none
}

.gnb .layer-popup {
    display: none;
    overflow-y: auto;
    line-height: 1.33;
    letter-spacing: -4px;
    text-align: center
}

.gnb .layer-popup, .gnb .layer-popup-dim {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3100
}

.gnb .layer-popup-dim {
    background: #000;
    opacity: .68
}

.gnb .layer-popup:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle
}

.gnb .layer-popup__inner {
    display: inline-block;
    position: relative;
    width: auto;
    min-width: 448px;
    max-width: 648px;
    padding: 24px;
    border-radius: 20px;
    background: #fff;
    line-height: 1.33;
    text-align: left;
    vertical-align: middle;
    letter-spacing: 0
}

.gnb .layer-popup__title {
    margin: 0 28px 16px 0;
    font-weight: 700;
    font-size: 24px
}

.gnb .layer-popup__contents {
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
    font-size: 18px;
    line-height: 1.6
}

.gnb .layer-popup .icon--information {
    display: block;
    width: 48px;
    height: 48px;
    margin: 0 auto 16px
}

.gnb .layer-popup__information-text {
    font-weight: 700;
    text-align: center
}

.gnb .layer-popup__cta {
    display: inline-block;
    width: 50%;
    padding: 0 8px
}

.gnb .layer-popup__cta-wrap {
    padding: 16px 0 24px;
    margin: 8px -8px -24px;
    text-align: center;
    font-size: 0
}

.gnb .layer-popup__cta .cta {
    width: 100%
}

    .gnb .layer-popup__cta .cta ~ .cta {
        margin-left: 16px
    }

.gnb .layer-popup__close {
    display: block;
    overflow: hidden;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 48px;
    height: 48px
}

    .gnb .layer-popup__close svg.icon {
        position: absolute;
        left: 12px;
        top: 12px;
        width: 24px;
        height: 24px
    }

.gnb__popup-privacy .link-text {
    display: inline-block;
    color: #1428a0;
    font-size: 14px;
    text-decoration: underline;
    vertical-align: top
}

.gnb .checkbox-radio ~ .check-text {
    clear: both;
    padding: 3px 0 0 42px;
    color: #363636;
    font-size: 14px
}

.gnb .checkbox-radio ~ .check-text--error {
    color: #fd020e
}

.gnb .checkbox-radio ~ .checkbox-radio {
    margin-top: 10px
}

@media only screen and (min-width:768px) and (max-width:1440px) {
    .gnb .layer-popup__inner {
        min-width: 31.11111111vw;
        max-width: 45vw;
        padding: 1.66666667vw;
        border-radius: 1.38888889vw
    }

    .gnb .layer-popup__title {
        margin: 0 1.94444444vw 1.11111111vw 0;
        font-size: 1.66666667vw
    }

    .gnb .layer-popup__contents {
        font-size: 1.25vw
    }

    .gnb .layer-popup .icon--information {
        width: 3.33333333vw;
        height: 3.33333333vw;
        margin-bottom: 1.11111111vw
    }

    .gnb .layer-popup__cta {
        padding: 0 .55555556vw
    }

    .gnb .layer-popup__cta-wrap {
        padding: 1.11111111vw 0 1.66666667vw;
        margin: .55555556vw -.55555556vw -1.66666667vw
    }

    .gnb .layer-popup__cta .cta ~ .cta {
        margin-left: 1.11111111vw
    }

    .gnb .layer-popup__close {
        right: .83333333vw;
        top: .83333333vw;
        width: 3.33333333vw;
        height: 3.33333333vw
    }

        .gnb .layer-popup__close svg.icon {
            left: .83333333vw;
            top: .83333333vw;
            width: 1.66666667vw;
            height: 1.66666667vw
        }

    .gnb__popup-privacy .link-text {
        font-size: .97222222vw
    }

    .gnb .checkbox-radio ~ .check-text {
        padding: .20833333vw 0 0 2.91666667vw;
        font-size: .97222222vw
    }

    .gnb .checkbox-radio ~ .checkbox-radio {
        margin-top: .69444444vw
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) {
    .gnb__bar {
        max-width: 100%
    }

    .gnb__logo {
        width: 8.33333333vw
    }

        .gnb__logo:after {
            height: 1.11111111vw
        }

    .gnb__main {
        margin-left: 4.44444444vw
    }

    .gnb__depth1-link {
        font-size: .97222222vw
    }

    .gnb__depth2-inner {
        max-width: 100%
    }

    .gnb__depth2 {
        width: 18.05555556vw;
        padding: .55555556vw 0
    }

    .gnb__depth2-title-wrap {
        padding-top: .55555556vw
    }

    .gnb__depth2-title {
        height: 5.55555556vw;
        font-size: 2.22222222vw
    }

    .gnb__depth2-menu + .gnb__depth2-menu {
        margin-top: 1.11111111vw
    }

    .gnb__depth2-link {
        padding: .55555556vw 2.22222222vw .55555556vw 0;
        font-size: 1.25vw
    }

        .gnb__depth2-link[target=_blank] .gnb__depth2-link-text .icon {
            width: 1.11111111vw;
            height: 1.11111111vw
        }

    .gnb__depth2-close {
        right: .83333333vw;
        top: 3.33333333vw;
        width: 3.33333333vw;
        height: 3.33333333vw;
        margin-top: -1.66666667vw
    }

        .gnb__depth2-close svg.icon {
            left: .83333333vw;
            top: .83333333vw;
            width: 1.66666667vw;
            height: 1.66666667vw
        }

    .gnb__depth3-wrap {
        left: 26.25vw;
        width: 29.44444444vw
    }

    .gnb__depth3 {
        padding: .55555556vw 0
    }

    .gnb__depth3-menu ~ .gnb__depth3-menu {
        margin-top: 1.11111111vw
    }

    .gnb__depth3-link {
        padding: .55555556vw 0;
        font-size: 1.25vw
    }

    .gnb__feature-container {
        top: 6.11111111vw;
        width: 34.51388889vw;
        padding: 0 5.76388889vw
    }

        .gnb__feature-container .image {
            width: 22.91666667vw;
            height: 22.91666667vw;
            border-radius: 1.38888889vw
        }

    .gnb__feature-container-contents {
        padding: 1.66666667vw .55555556vw;
        font-size: 1.25vw
    }

        .gnb__feature-container-contents .cta {
            margin: .41666667vw 0 -.55555556vw
        }

    .gnb__utility {
        margin: 1.94444444vw 0 0 2.5vw
    }

        .gnb__utility > li + li {
            margin-left: 1.66666667vw
        }

        .gnb__utility > li > a, .gnb__utility > li > a svg.icon {
            width: 1.66666667vw;
            height: 1.66666667vw
        }

        .gnb__utility .gnb__login-layer {
            width: 14.58333333vw;
            padding: 1.80555556vw 1.66666667vw;
            border-radius: 1.38888889vw
        }

            .gnb__utility .gnb__login-layer .gnb__utility-menu li + li {
                margin-top: 1.38888889vw
            }

            .gnb__utility .gnb__login-layer .gnb__user-name, .gnb__utility .gnb__login-layer .gnb__utility-link {
                font-size: .97222222vw
            }

            .gnb__utility .gnb__login-layer .gnb__user-name {
                padding-bottom: .69444444vw;
                margin-bottom: .69444444vw
            }

    .gnb .gnb__depth3-link[target=_blank] .gnb__depth3-link-text .icon {
        width: 1.11111111vw;
        height: 1.11111111vw
    }

    .gnb .gnb__depth2-menu.has-depth-menu.active .gnb__depth2-link .icon, .gnb .gnb__depth2-menu.has-depth-menu .gnb__depth2-link:focus .icon, .gnb .gnb__depth2-menu.has-depth-menu .gnb__depth2-link:hover .icon {
        width: 1.66666667vw;
        height: 1.66666667vw;
        margin-top: -.83333333vw
    }
}

@media only screen and (max-width:1279px) {
    .gnb {
        min-width: 360px
    }

    .gnb__bar {
        height: 56px
    }

    .gnb__bar-inner {
        padding: 12px 16px
    }

    .gnb__logo {
        width: 120px;
        height: 32px
    }

    .gnb__main {
        margin-left: 0
    }

    .gnb__mobile-menu {
        display: block;
        float: right;
        font-size: 0;
        margin: -4px 0
    }

        .gnb__mobile-menu [class*=-btn] {
            display: inline-block;
            position: relative;
            width: 40px;
            height: 40px;
            vertical-align: middle
        }

            .gnb__mobile-menu [class*=-btn] .icon {
                position: absolute;
                left: 8px;
                top: 8px;
                width: 24px;
                height: 24px
            }

        .gnb__mobile-menu .gnb__cart-btn .gnb__cart-in-number {
            position: absolute;
            top: 5px;
            left: 23px;
            z-index: 2000;
            min-width: 12px;
            height: 12px;
            padding: 0 2px;
            border-radius: 6px;
            background-color: #d93b30;
            color: #fff;
            font-size: 10px;
            font-weight: 700;
            text-align: center;
            line-height: 14px;
            letter-spacing: normal;
            text-indent: 1px
        }

    .gnb__utility {
        display: none
    }

    .gnb__menu-wrap {
        position: fixed;
        right: -360px;
        top: 0;
        bottom: 0;
        width: 360px;
        height: 100%;
        z-index: 2100;
        -webkit-transition: right .3s cubic-bezier(.6,0,.4,1);
        transition: right .3s cubic-bezier(.6,0,.4,1)
    }

        .gnb__menu-wrap:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #fff
        }

        .gnb__menu-wrap.open {
            right: 0
        }

            .gnb__menu-wrap.open:before {
                content: "";
                position: fixed;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: #000;
                opacity: .68
            }

    .gnb__menu-close {
        position: absolute;
        top: 8px;
        right: 16px;
        width: 40px;
        height: 40px;
        opacity: 0;
        z-index: 2100;
        -webkit-transition: opacity .2s cubic-bezier(.4,0,.2,1);
        transition: opacity .2s cubic-bezier(.4,0,.2,1)
    }

        .gnb__menu-close svg.icon {
            position: absolute;
            left: 8px;
            top: 8px;
            width: 24px;
            height: 24px
        }

    .gnb__menu-wrap.open .gnb__menu-close {
        display: block;
        opacity: 1
    }

    .gnb__depth1-container {
        overflow-y: auto;
        position: absolute;
        top: 56px;
        left: 0;
        bottom: 0;
        width: 100%;
        padding-bottom: 80px;
        z-index: 2100
    }

    .gnb__main, .gnb__sub {
        float: none
    }

    .gnb__depth1 {
        visibility: hidden;
        width: 100%;
        opacity: 0;
        -webkit-transition: all .2s cubic-bezier(.4,0,.2,1);
        transition: all .2s cubic-bezier(.4,0,.2,1)
    }

        .gnb__depth1.open {
            visibility: visible;
            opacity: 1;
            -webkit-transition: all .5s cubic-bezier(.4,0,.2,1) .2s;
            transition: all .5s cubic-bezier(.4,0,.2,1) .2s
        }

        .gnb__depth1:after {
            content: "";
            display: block;
            height: 1px;
            margin: 16px 24px;
            background: #ddd
        }

    .gnb__depth1-menu {
        display: table;
        width: 100%;
        height: auto
    }

        .gnb__depth1-menu + .gnb__depth1-menu {
            margin-top: 0
        }

    .gnb__depth1-link {
        display: table-cell;
        left: 0;
        width: 100%;
        height: 56px;
        padding: 0 24px;
        border-radius: 0;
        color: #000;
        font-weight: 400;
        font-size: 20px;
        vertical-align: middle;
        opacity: 1;
        -webkit-transition: left .3s cubic-bezier(.6,0,.4,1),opacity .3s cubic-bezier(.4,0,.2,1);
        transition: left .3s cubic-bezier(.6,0,.4,1),opacity .3s cubic-bezier(.4,0,.2,1)
    }

        .gnb__depth1-link svg.icon {
            width: 16px;
            height: 16px
        }

        .gnb__depth1-link[target=_blank] .gnb__depth1-link-text .icon {
            display: inline-block;
            vertical-align: middle
        }

    .gnb__depth1-menu.active .gnb__depth1-link, .gnb__depth1-menu .gnb__depth1-link:active, .gnb__depth1-menu .gnb__depth1-link:focus, .gnb__depth1-menu .gnb__depth1-link:hover {
        color: inherit;
        background: none
    }

    .gnb__depth1-menu.has-depth-menu .gnb__depth1-link {
        position: relative;
        padding-right: 56px
    }

        .gnb__depth1-menu.has-depth-menu .gnb__depth1-link:before {
            content: none
        }

        .gnb__depth1-menu.has-depth-menu .gnb__depth1-link .icon {
            display: block;
            position: absolute;
            right: 25px;
            top: 50%;
            margin-top: -8px
        }

    .gnb__sub .gnb__depth1 {
        float: none
    }

    .gnb__depth-back-wrap {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 56px;
        width: 100%;
        padding-right: 76px;
        background: #fff;
        z-index: 2100
    }

        .gnb__depth-back-wrap .gnb__depth-back {
            display: block;
            position: absolute;
            left: 16px;
            top: 8px;
            width: 40px;
            height: 40px
        }

            .gnb__depth-back-wrap .gnb__depth-back svg.icon {
                position: absolute;
                top: 8px;
                left: 8px;
                width: 24px;
                height: 24px
            }

    .gnb__depth2-wrap {
        overflow: hidden;
        position: fixed;
        left: auto;
        right: -360px;
        top: 0;
        bottom: 0;
        width: 360px;
        opacity: 0;
        background: #fff;
        z-index: 2100;
        -webkit-transition: right .4s cubic-bezier(.6,0,.4,1),opacity .2s cubic-bezier(.6,0,.4,1);
        transition: right .4s cubic-bezier(.6,0,.4,1),opacity .2s cubic-bezier(.6,0,.4,1)
    }

        .gnb__depth2-wrap .gnb__depth2-inner {
            visibility: hidden;
            overflow: hidden;
            overflow-y: auto;
            position: absolute;
            left: 0;
            top: 56px;
            bottom: 0;
            width: 360px;
            height: auto;
            padding: 0 0 48px;
            opacity: 1
        }

        .gnb__depth2-wrap.open {
            right: 0;
            opacity: 1;
            -webkit-transition-delay: 0s,.2s;
            transition-delay: 0s,.2s
        }

            .gnb__depth2-wrap.open .gnb__depth2-inner {
                visibility: visible
            }

    .gnb__depth2-title {
        height: 56px;
        padding-left: 24px;
        font-size: 24px;
        background: #fff
    }

    .gnb__depth2-title-wrap {
        position: fixed;
        width: 360px;
        opacity: 1;
        margin-top: 0;
        padding: 0 24px 0 0;
        z-index: 2000
    }

    .gnb__depth2 {
        width: auto;
        height: auto;
        padding: 56px 0 0;
        opacity: 0;
        -webkit-transition: all .2s cubic-bezier(.4,0,.2,1);
        transition: all .2s cubic-bezier(.4,0,.2,1)
    }

    .gnb__depth2-menu {
        display: block;
        position: relative
    }

        .gnb__depth2-menu + .gnb__depth2-menu {
            margin-top: 0
        }

    .gnb__depth2-link {
        display: table;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;
        font-size: 18px
    }

    .gnb__depth2-link-text {
        display: table-cell;
        height: 56px;
        padding: 0 24px;
        vertical-align: middle
    }

        .gnb__depth2-link-text:before {
            content: none !important
        }

    .gnb__depth2-link.open {
        opacity: 1;
        -webkit-transition: all .3s cubic-bezier(.2,.6,.4,1) .15s;
        transition: all .3s cubic-bezier(.2,.6,.4,1) .15s
    }

    .gnb__depth2-menu.has-depth-menu.open .gnb__depth2-link {
        font-weight: 700
    }

    .gnb__depth2-dropdown-cta {
        display: block;
        position: absolute;
        right: 4px;
        top: 0;
        width: 56px;
        height: 56px
    }

    .gnb__depth2-menu.has-depth-menu {
        padding-right: 65px
    }

    .gnb__depth2 .gnb__depth2-dropdown-cta svg.icon {
        display: block;
        position: absolute;
        left: 20px;
        top: 20px;
        width: 16px;
        height: 16px
    }

    .gnb__depth2-close {
        display: none
    }

    .gnb__depth2-menu.has-depth-menu.open .gnb__depth2-dropdown-cta .icon {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg)
    }

    .open .gnb__depth2 {
        opacity: 1
    }

    .gnb__feature-container {
        visibility: hidden;
        position: static;
        width: auto;
        margin-top: 48px;
        padding: 0 24px;
        border: 0
    }

        .gnb__feature-container .image {
            width: 100%;
            height: 100%
        }

    .gnb__feature-container-contents {
        padding: 16px 8px
    }

    .gnb__feature-container-description {
        font-size: 16px
    }

    .gnb__feature-container .cta {
        padding: 10px 0 11px;
        font-size: 14px;
        line-height: 1.33;
        border-bottom-width: 2px
    }

        .gnb__feature-container .cta:before {
            bottom: 8px;
            height: 2px
        }

    .gnb__depth2-wrap.open .gnb__feature-container {
        visibility: visible
    }

    .gnb__depth3-wrap {
        position: static;
        visibility: hidden;
        height: 0;
        width: 360px;
        -webkit-transition: all .5s cubic-bezier(.4,0,.2,1);
        transition: all .5s cubic-bezier(.4,0,.2,1)
    }

        .gnb__depth3-wrap:after {
            content: "";
            display: block;
            margin: 16px 24px 0;
            border-bottom: 1px solid #ddd
        }

        .gnb__depth3-wrap .gnb__depth3-inner {
            left: 0;
            opacity: 1
        }

    .gnb__depth2-menu.open .gnb__depth3-wrap {
        visibility: visible;
        opacity: 1
    }

    .gnb__depth3 {
        width: 100%;
        padding: 0
    }

    .gnb__depth3-menu {
        display: table;
        width: 100%
    }

        .gnb__depth3-menu + .gnb__depth3-menu {
            margin-top: 0
        }

    .gnb__depth3-link {
        display: table-cell;
        height: 48px;
        padding: 0 40px;
        font-size: 16px;
        vertical-align: middle
    }

    .gnb__utility-mobile {
        width: 100%
    }

        .gnb__utility-mobile .gnb__utility-menu {
            visibility: hidden;
            opacity: 0;
            -webkit-transition: all .5s cubic-bezier(.4,0,.2,1);
            transition: all .5s cubic-bezier(.4,0,.2,1)
        }

            .gnb__utility-mobile .gnb__utility-menu > li {
                display: table;
                width: 100%
            }

        .gnb__utility-mobile .gnb__user-name, .gnb__utility-mobile .gnb__utility-link {
            display: table-cell;
            position: relative;
            height: 56px;
            left: 0;
            padding: 0 24px;
            color: #000;
            font-weight: 400;
            font-size: 20px;
            vertical-align: middle;
            opacity: 1;
            -webkit-transition: left .3s cubic-bezier(.6,0,.4,1),opacity .3s cubic-bezier(.4,0,.2,1);
            transition: left .3s cubic-bezier(.6,0,.4,1),opacity .3s cubic-bezier(.4,0,.2,1)
        }

            .gnb__utility-mobile .gnb__user-name.open, .gnb__utility-mobile .gnb__utility-link.open {
                opacity: 1
            }

        .gnb__utility-mobile .gnb__user-name {
            display: table;
            width: 100%;
            font-weight: 700
        }

        .gnb__utility-mobile .gnb__user-name-inner {
            display: table-cell;
            height: 56px;
            vertical-align: middle
        }

    .open .gnb__utility-menu {
        visibility: visible;
        opacity: 1;
        -webkit-transition: all .5s cubic-bezier(.2,.6,.4,1) .2s;
        transition: all .5s cubic-bezier(.2,.6,.4,1) .2s
    }

    .gnb .gnb__depth2-menu.has-depth-menu.active .gnb__depth2-link .icon, .gnb .gnb__depth2-menu.has-depth-menu .gnb__depth2-link:focus .icon, .gnb .gnb__depth2-menu.has-depth-menu .gnb__depth2-link:hover .icon {
        display: none
    }

    .gnb__depth1-container.slide .gnb__depth1-link, .gnb__depth1-container.slide .gnb__utility-link {
        left: -360px;
        opacity: 0
    }

    .gnb .gnb__depth2-wrap.open .gnb__feature-container {
        opacity: 1
    }
}

@media only screen and (max-width:767px) {
    .gnb .layer-popup__inner {
        min-width: 86.66666667vw;
        padding: 6.66666667vw;
        border-radius: 5.55555556vw
    }

    .gnb .layer-popup__title {
        margin: 0 7.77777778vw 4.44444444vw 0;
        font-size: 6.11111111vw
    }

    .gnb .layer-popup__contents {
        font-size: 3.88888889vw
    }

    .gnb .layer-popup .icon--information {
        width: 13.33333333vw;
        height: 13.33333333vw;
        margin-bottom: 4.44444444vw
    }

    .gnb .layer-popup__cta {
        width: 100%;
        padding: 1.11111111vw 0
    }

    .gnb .layer-popup__cta-wrap {
        padding: 3.33333333vw 0 5.55555556vw;
        margin: 2.22222222vw 0 -6.66666667vw
    }

    .gnb .layer-popup__cta .cta ~ .cta {
        margin-left: 0
    }

    .gnb .layer-popup__close {
        right: 3.33333333vw;
        top: 3.33333333vw;
        width: 13.33333333vw;
        height: 13.33333333vw
    }

        .gnb .layer-popup__close svg.icon {
            left: 3.33333333vw;
            top: 3.33333333vw;
            width: 6.66666667vw;
            height: 6.66666667vw
        }

    .gnb__popup-privacy .link-text {
        font-size: 3.33333333vw
    }

    .gnb .checkbox-radio ~ .check-text {
        padding: .83333333vw 0 0 9.16666667vw;
        font-size: 3.33333333vw
    }

    .gnb .checkbox-radio ~ .checkbox-radio {
        margin-top: 2.77777778vw
    }
}

@media only screen and (max-width:360px) {
    .gnb {
        min-width: 100%
    }

    .gnb__depth2-wrap, .gnb__depth2-wrap .gnb__depth2-inner, .gnb__depth3-wrap.open, .gnb__menu-wrap {
        width: 100%
    }
}
