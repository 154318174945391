body, h1, h2, h3, h4, h5, h6 {
    font-family: Microsoft JhengHei,SamsungOne,arial,sans-serif
}

/* added style */
.footer-language-wrap a {
    text-decoration: none;
}

.footer-terms__list {
    padding-left: 0px;    
}

.bg-black {
    background-color: #000 !important;
    color: #fff
}

.bg-white {
    background-color: #fff !important;
    color: #000
}

.bg-gray {
    background: #f7f7f7 !important
}

.bg-dark-gray {
    background-color: #555 !important;
    color: #fff
}

.bg-light-gray {
    background-color: #f4f4f4 !important;
    color: #000
}

.bg-transparent {
    background-color: transparent !important
}

.text-color--black {
    color: #000 !important
}

.text-color--white {
    color: #fff !important
}

.align-center {
    text-align: center !important
}

.align-left {
    text-align: left !important
}

.align-right {
    text-align: right !important
}

.background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 300;
    color: #000
}

.margin-top--narrow {
    padding-top: 16px
}

.margin-top--normal {
    padding-top: 32px
}

.margin-top--wide {
    padding-top: 56px
}

.margin-bottom--narrow {
    padding-bottom: 16px
}

.margin-bottom--normal {
    padding-bottom: 32px
}

.margin-bottom--wide {
    padding-bottom: 56px
}

.s-buffer-top {
    margin-top: 88px !important
}

.s-buffer-bottom {
    margin-bottom: 88px !important
}

.top-margin-narrow {
    margin-top: 2px !important
}

.top-margin-normal {
    margin-top: 13px !important
}

.top-margin-wide {
    margin-top: 32px !important
}

.top-margin-very-wide {
    margin-top: 42px !important
}

.container-width-large {
    max-width: 1920px !important
}

[data-font-size-pc="64"] {
    font-size: 48px
}

[data-font-size-pc="48"] {
    font-size: 38px
}

[data-font-size-pc="32"] {
    font-size: 32px
}

[data-font-size-pc="24"] {
    font-size: 24px
}

[data-font-size-pc="22"] {
    font-size: 28px
}

[data-font-size-pc="20"] {
    font-size: 22px
}

[data-font-size-pc="18"] {
    font-size: 18px
}

[data-font-size-pc="14"] {
    font-size: 14px
}

.skip-bar a {
    overflow: hidden;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    background: #000;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft JhengHei,SamsungOne,arial,sans-serif
}

    .skip-bar a:focus {
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px
    }

@media only screen and (min-width:769px) and (max-width:1440px) {
    .margin-top--narrow {
        padding-top: 1.11111111vw
    }

    .margin-top--normal {
        padding-top: 2.22222222vw
    }

    .margin-top--wide {
        padding-top: 3.88888889vw
    }

    .margin-bottom--narrow {
        padding-bottom: 1.11111111vw
    }

    .margin-bottom--normal {
        padding-bottom: 2.22222222vw
    }

    .margin-bottom--wide {
        padding-bottom: 3.88888889vw
    }

    [data-font-size-pc="64"] {
        font-size: 3.33333333vw
    }

    [data-font-size-pc="48"] {
        font-size: 2.63888889vw
    }

    [data-font-size-pc="32"] {
        font-size: 2.22222222vw
    }

    [data-font-size-pc="24"] {
        font-size: 1.66666667vw
    }

    [data-font-size-pc="22"] {
        font-size: 1.94444444vw
    }

    [data-font-size-pc="20"] {
        font-size: 1.52777778vw
    }

    [data-font-size-pc="18"] {
        font-size: 1.25vw
    }

    [data-font-size-pc="14"] {
        font-size: .97222222vw
    }
}

@media only screen and (max-width:768px) {
    .margin-top--narrow {
        padding-top: 4.44444444vw
    }

    .margin-top--normal {
        padding-top: 6.66666667vw
    }

    .margin-top--wide {
        padding-top: 8.88888889vw
    }

    .margin-bottom--narrow {
        padding-bottom: 4.44444444vw
    }

    .margin-bottom--normal {
        padding-bottom: 6.66666667vw
    }

    .margin-bottom--wide {
        padding-bottom: 8.88888889vw
    }

    [data-font-size-mo="70"] {
        font-size: 8.88888889vw
    }

    [data-font-size-mo="56"] {
        font-size: 7.22222222vw
    }

    [data-font-size-mo="44"] {
        font-size: 6.11111111vw
    }

    [data-font-size-mo="34"] {
        font-size: 4.44444444vw
    }

    [data-font-size-mo="32"] {
        font-size: 3.88888889vw
    }

    [data-font-size-mo="24"], [data-font-size-mo="28"] {
        font-size: 3.33333333vw
    }
}

@media only screen and (max-width:360px) {
    .margin-top--narrow {
        padding-top: 16px
    }

    .margin-top--normal {
        padding-top: 24px
    }

    .margin-top--wide {
        padding-top: 32px
    }

    .margin-bottom--narrow {
        padding-bottom: 16px
    }

    .margin-bottom--normal {
        padding-bottom: 24px
    }

    .margin-bottom--wide {
        padding-bottom: 32px
    }

    [data-font-size-mo="70"] {
        font-size: 32px
    }

    [data-font-size-mo="56"] {
        font-size: 26px
    }

    [data-font-size-mo="44"] {
        font-size: 22px
    }

    [data-font-size-mo="34"] {
        font-size: 16px
    }

    [data-font-size-mo="32"] {
        font-size: 14px
    }

    [data-font-size-mo="24"], [data-font-size-mo="28"] {
        font-size: 12px
    }

    .skip-bar a:focus {
        width: 360px
    }
}

@font-face {
    font-display: swap;
    font-family: SamsungOne;
    font-style: normal;
    font-weight: 400;
    src: local("SamsungOne"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-400.woff2) format("woff2"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-400.woff) format("woff"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-400.ttf) format("truetype"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-400.eot?#iefix) format("embedded-opentype")
}

@font-face {
    font-display: swap;
    font-family: SamsungOne;
    font-style: normal;
    font-weight: 700;
    src: local("SamsungOne"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-700.woff2) format("woff2"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-700.woff) format("woff"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-700.ttf) format("truetype"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-700.eot?#iefix) format("embedded-opentype")
}

@font-face {
    font-display: swap;
    font-family: SamsungSharpSans;
    font-style: normal;
    font-weight: 700;
    src: local("SamsungSharpSans"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungSharpSansBd.woff2) format("woff2"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungSharpSansBd.woff) format("woff"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungSharpSansBd.eot?#iefix) format("embedded-opentype")
}

@font-face {
    font-display: swap;
    font-family: SamsungSharpSans;
    font-style: normal;
    font-weight: 400;
    src: local("SamsungSharpSans"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungSharpSansMd.woff2) format("woff2"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungSharpSansMd.woff) format("woff"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungSharpSansMd.eot?#iefix) format("embedded-opentype")
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

main {
    display: block
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

pre {
    font-family: monospace;
    font-size: 1em
}

a {
    background-color: transparent
}

abbr[title] {
    border-bottom: none;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

b, strong {
    font-weight: bolder
}

code, kbd, samp {
    font-family: monospace;
    font-size: 1em
}

small {
    font-size: 80%
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

img {
    border-style: none
}

button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button, input {
    overflow: visible
}

button, select {
    text-transform: none
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button
}

    [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
        border-style: none;
        padding: 0
    }

    [type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
        outline: 1px dotted ButtonText
    }

fieldset {
    padding: .35em .75em .625em
}

legend {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}

progress {
    vertical-align: baseline
}

textarea {
    overflow: auto
}

[type=checkbox], [type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

    [type=search]::-webkit-search-decoration {
        -webkit-appearance: none
    }

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

details {
    display: block
}

summary {
    display: list-item
}

[hidden], template {
    display: none
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    border-radius: 0;
    cursor: pointer;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none
}

abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
    margin: 0;
    padding: 0;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block
}

h1, h2, h3, h4, h5, h6 {
    font-size: 100%
}

li, ol, ul {
    list-style: outside none none
}

img {
    vertical-align: top
}

fieldset, img {
    border: 0
}

a {
    text-decoration: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

hr {
    display: none
}

input, select, textarea {
    vertical-align: middle;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

address, em {
    font-style: normal
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

    .hidden, legend, table caption {
        display: block;
        overflow: hidden;
        position: absolute !important;
        width: 1px;
        height: 1px;
        font-size: 1px;
        line-height: 1px;
        clip: rect(1px,1px,1px,1px)
    }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #666;
    font-weight: 400
}

input:-moz-placeholder, input:-ms-input-placeholder, input::-webkit-input-placeholder, input::placeholder, textarea:-moz-placeholder, textarea:-ms-input-placeholder, textarea::-webkit-input-placeholder, textarea::placeholder {
    color: #666;
    font-weight: 400
}

.image {
    position: relative
}

.image, .image__main, .image__preview {
    display: inline-block;
    width: 100%
}

    .image .hover-scale {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: -webkit-transform .3s linear;
        transition: -webkit-transform .3s linear;
        transition: transform .3s linear;
        transition: transform .3s linear,-webkit-transform .3s linear
    }

        .image .hover-scale:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1)
        }

    .image__preview + .image__main {
        position: absolute;
        left: 0;
        top: 0
    }

    .image .hover-image {
        opacity: 1;
        visibility: visible;
        -webkit-transition: visibility 0s,opacity .1s cubic-bezier(.39,.575,.565,1) .1s;
        transition: visibility 0s,opacity .1s cubic-bezier(.39,.575,.565,1) .1s
    }

.image__hover-image--hover {
    position: absolute;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    left: 0;
    -webkit-transition: visibility 0s .2s,opacity .2s cubic-bezier(.47,0,.745,.715);
    transition: visibility 0s .2s,opacity .2s cubic-bezier(.47,0,.745,.715)
}

.image:hover .hover-image {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s 1s,opacity .2s cubic-bezier(.47,0,.745,.715);
    transition: visibility 0s 1s,opacity .2s cubic-bezier(.47,0,.745,.715)
}

.image:hover .image__hover-image--hover {
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s,opacity .1s cubic-bezier(.39,.575,.565,1) .1s;
    transition: visibility 0s,opacity .1s cubic-bezier(.39,.575,.565,1) .1s
}

.image .lazy-load {
    visibility: hidden
}

.image-360 {
    width: 414px;
    height: 414px;
    overflow: hidden
}

    .image-360 [data-akamai-viewer] div {
        height: 100%
    }

    .image-360 [data-akamai-viewer] canvas {
        display: block;
        max-width: 100%;
        height: 100%;
        margin: 0 auto
    }

.footer .footer-back-to-top {
    height: 56px;
    margin: 0 auto;
    max-width: 1440px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    text-align: right
}

.footer .footer-back-to-top__cta {
    display: inline-block;
    position: relative;
    padding: 18px 25px 19px;
    font-family: Microsoft JhengHei,SamsungOne,arial,sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.33;
    text-align: right;
    color: #000
}

    .footer .footer-back-to-top__cta:after {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 5px;
        background: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-regular-navigation-close-up.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        vertical-align: middle;
        content: ""
    }

@media only screen and (min-width:769px) and (max-width:1440px) {
    .footer .footer-back-to-top {
        height: 3.88888889vw
    }

    .footer .footer-back-to-top__cta {
        padding: 1.25vw 1.73611111vw 1.31944444vw;
        font-size: .97222222vw
    }

        .footer .footer-back-to-top__cta:after {
            width: 1.11111111vw;
            height: 1.11111111vw;
            margin-left: .34722222vw
        }
}

@media only screen and (max-width:768px) {
    .footer .footer-back-to-top {
        display: block;
        height: 13.88888889vw;
        border-bottom: none;
        text-align: center
    }

        .footer .footer-back-to-top:after {
            content: "";
            display: block;
            width: auto;
            height: 1px;
            background-color: #e7e7e7;
            margin: 0 24px
        }

    .footer .footer-back-to-top__cta {
        display: block;
        text-align: center;
        padding: 4.16666667vw 0 3.88888889vw;
        font-size: 3.88888889vw
    }

        .footer .footer-back-to-top__cta:after {
            width: 5vw;
            height: 5.83333333vw;
            margin-left: 3.55555556vw
        }
}

.footer .footer-bottom {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    font-size: 0
}

    .footer .footer-bottom .footer-copyright-wrap {
        position: relative;
        display: table;
        width: 100%;
        height: auto;
        border-bottom: 1px solid #d9d9d9
    }

        .footer .footer-bottom .footer-copyright-wrap .footer-copyright-align {
            display: table-cell;
            vertical-align: middle;
            padding: 17px 12px 15px 24px
        }

            .footer .footer-bottom .footer-copyright-wrap .footer-copyright-align .footer-copyright, .footer .footer-bottom .footer-copyright-wrap .footer-copyright-align .footer-legal {
                display: block;
                max-width: 1023px;
                font-size: 12px;
                line-height: 1.33
            }

        .footer .footer-bottom .footer-copyright-wrap .footer-local-logo {
            display: table-cell;
            padding: 16px 24px 16px 12px;
            vertical-align: middle
        }

        .footer .footer-bottom .footer-copyright-wrap .footer-local-logo--wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: reverse;
            -ms-flex-flow: row-reverse;
            flex-flow: row-reverse;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-size: 0
        }

        .footer .footer-bottom .footer-copyright-wrap .footer-local-logo__item {
            max-width: 188px
        }

            .footer .footer-bottom .footer-copyright-wrap .footer-local-logo__item:after {
                content: "";
                display: block;
                clear: both;
                height: 0;
                visibility: hidden
            }

            .footer .footer-bottom .footer-copyright-wrap .footer-local-logo__item:nth-child(n+1) {
                margin-left: 24px
            }

        .footer .footer-bottom .footer-copyright-wrap .footer-local-logo__link {
            display: block
        }

        .footer .footer-bottom .footer-copyright-wrap .footer-local-logo .responsive-img {
            max-width: 100%
        }

    .footer .footer-bottom .footer-language-wrap {
        position: relative;
        height: 72px;
        padding-left: 24px
    }

        .footer .footer-bottom .footer-language-wrap .footer-sns {
            display: table;
            height: 100%;
            float: right;
            margin-right: 24px
        }

        .footer .footer-bottom .footer-language-wrap .footer-sns__title {
            display: table-cell;
            vertical-align: middle;
            height: 16px;
            font-family: Microsoft JhengHei,SamsungOne,arial,sans-serif;
            font-size: 12px;
            line-height: 1.33
        }

        .footer .footer-bottom .footer-language-wrap .footer-sns__list {
            display: table-cell;
            vertical-align: middle
        }

        .footer .footer-bottom .footer-language-wrap .footer-sns__item {
            display: inline-block;
            border-radius: 100%;
            margin-left: 15px
        }

        .footer .footer-bottom .footer-language-wrap .footer-sns__link {
            display: block;
            width: 25px;
            height: 25px
        }

        .footer .footer-bottom .footer-language-wrap .footer-sns .responsive-img {
            max-width: 100%
        }

        .footer .footer-bottom .footer-language-wrap .footer-language {
            display: table;
            float: left;
            height: 72px;
            padding-right: 24px
        }

            .footer .footer-bottom .footer-language-wrap .footer-language:after {
                display: block;
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 1px;
                height: 14px;
                margin-left: 24px;
                background-color: #d9d9d9;
                content: ""
            }

        .footer .footer-bottom .footer-language-wrap .footer-language__panel {
            display: none;
            position: absolute;
            left: -16px;
            bottom: 35px;
            z-index: 300
        }

        .footer .footer-bottom .footer-language-wrap .footer-language__anchor {
            display: table-cell;
            vertical-align: middle;
            font-weight: 700;
            font-size: 12px;
            line-height: 1.33;
            color: #000
        }

            .footer .footer-bottom .footer-language-wrap .footer-language__anchor:focus, .footer .footer-bottom .footer-language-wrap .footer-language__anchor:hover {
                color: #1428a0
            }

        .footer .footer-bottom .footer-language-wrap .footer-language__anchor--active + .footer-language__panel {
            display: block
        }

        .footer .footer-bottom .footer-language-wrap .footer-language__dimmed {
            display: none
        }

        .footer .footer-bottom .footer-language-wrap .footer-language__content {
            position: relative;
            min-width: 286px;
            padding: 8px 0;
            right: -20px;
            bottom: 20px;
            border: 1px solid #d9d9d9;
            border-radius: 12px;
            background-color: #fff;
            font-size: 14px
        }

            .footer .footer-bottom .footer-language-wrap .footer-language__content:after {
                display: block;
                position: absolute;
                bottom: -7px;
                left: 64px;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                width: 10px;
                height: 10px;
                border-color: #d9d9d9;
                border-style: solid;
                border-width: 0 1px 1px 0;
                background-color: #fff;
                content: ""
            }

        .footer .footer-bottom .footer-language-wrap .footer-language__link {
            display: block;
            position: relative;
            padding: 0 32px 0 16px;
            font-weight: 700;
            font-size: 14px;
            line-height: 32px;
            color: #000;
            text-transform: uppercase;
            word-break: break-all
        }

            .footer .footer-bottom .footer-language-wrap .footer-language__link:after {
                display: block;
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                right: 16px;
                width: 11px;
                height: 11px;
                background: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon_footer_language.svg) no-repeat;
                content: ""
            }

            .footer .footer-bottom .footer-language-wrap .footer-language__link:focus, .footer .footer-bottom .footer-language-wrap .footer-language__link:hover {
                color: #1428a0
            }

                .footer .footer-bottom .footer-language-wrap .footer-language__link:focus:after, .footer .footer-bottom .footer-language-wrap .footer-language__link:hover:after {
                    background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon_footer_language_active.svg)
                }

        .footer .footer-bottom .footer-language-wrap .footer-terms {
            display: table;
            max-width: 646px;
            height: 72px;
            padding: 0 0 0 13px
        }

        .footer .footer-bottom .footer-language-wrap .footer-terms__list {
            display: table-cell;
            vertical-align: middle;
            font-size: 0;
            text-align: left
        }

        .footer .footer-bottom .footer-language-wrap .footer-terms__item {
            display: inline-block
        }

        .footer .footer-bottom .footer-language-wrap .footer-terms__link {
            display: block;
            padding: 0 12px;
            font-size: 12px;
            line-height: 1.33;
            color: #000
        }

@media only screen and (min-width:769px) and (max-width:1440px) {
    .footer .footer-bottom .footer-copyright-wrap .footer-copyright-align {
        padding: 1.18055556vw .83333333vw 1.04166667vw 1.66666667vw
    }

        .footer .footer-bottom .footer-copyright-wrap .footer-copyright-align .footer-copyright, .footer .footer-bottom .footer-copyright-wrap .footer-copyright-align .footer-legal {
            max-width: 71.04166667vw;
            font-size: .83333333vw
        }

    .footer .footer-bottom .footer-copyright-wrap .footer-local-logo {
        max-width: 28.05555556vw;
        padding: 1.11111111vw 1.66666667vw 1.11111111vw .83333333vw
    }

    .footer .footer-bottom .footer-copyright-wrap .footer-local-logo__item {
        max-width: 13.05555556vw
    }

        .footer .footer-bottom .footer-copyright-wrap .footer-local-logo__item:nth-child(n+1) {
            margin-left: 1.66666667vw
        }

    .footer .footer-bottom .footer-language-wrap {
        height: 5vw;
        padding-left: 1.66666667vw
    }

        .footer .footer-bottom .footer-language-wrap .footer-sns {
            margin-right: 1.66666667vw
        }

        .footer .footer-bottom .footer-language-wrap .footer-sns__title {
            height: 1.11111111vw;
            font-size: .83333333vw
        }

        .footer .footer-bottom .footer-language-wrap .footer-sns__item {
            margin-left: 1.04166667vw
        }

        .footer .footer-bottom .footer-language-wrap .footer-sns__link {
            width: 1.73611111vw;
            height: 1.73611111vw
        }

        .footer .footer-bottom .footer-language-wrap .footer-language {
            height: 5vw;
            padding-right: 1.66666667vw
        }

            .footer .footer-bottom .footer-language-wrap .footer-language:after {
                height: .97222222vw;
                margin-left: 1.66666667vw
            }

        .footer .footer-bottom .footer-language-wrap .footer-language__anchor {
            font-size: .83333333vw
        }

        .footer .footer-bottom .footer-language-wrap .footer-language__panel {
            left: -1.11111111vw;
            bottom: 2.43055556vw
        }

        .footer .footer-bottom .footer-language-wrap .footer-language__content {
            min-width: 19.86111111vw;
            padding: .55555556vw 0;
            right: -1.38888889vw;
            bottom: 1.38888889vw;
            font-size: .97222222vw
        }

            .footer .footer-bottom .footer-language-wrap .footer-language__content:after {
                bottom: -.48611111vw;
                left: 4.44444444vw;
                width: .69444444vw;
                height: .69444444vw
            }

        .footer .footer-bottom .footer-language-wrap .footer-language__link {
            padding: 0 2.22222222vw 0 1.11111111vw;
            font-size: .83333333vw
        }

            .footer .footer-bottom .footer-language-wrap .footer-language__link:after {
                right: 1.11111111vw;
                width: .76388889vw;
                height: .76388889vw
            }

        .footer .footer-bottom .footer-language-wrap .footer-terms {
            height: 5vw;
            padding: 0 0 0 .90277778vw
        }

        .footer .footer-bottom .footer-language-wrap .footer-terms__link {
            padding: 0 .97222222vw;
            font-size: .83333333vw
        }
}

@media only screen and (max-width:768px) {
    .footer .footer-bottom {
        padding: 9.72222222vw 6.66666667vw 6.11111111vw
    }

        .footer .footer-bottom .footer-copyright-wrap {
            display: block;
            border-bottom: none
        }

            .footer .footer-bottom .footer-copyright-wrap:after {
                display: none
            }

            .footer .footer-bottom .footer-copyright-wrap .footer-copyright-align {
                min-height: auto;
                padding: 0 0 8.88888889vw
            }

                .footer .footer-bottom .footer-copyright-wrap .footer-copyright-align .footer-copyright, .footer .footer-bottom .footer-copyright-wrap .footer-copyright-align .footer-legal {
                    font-size: 3.33333333vw
                }

            .footer .footer-bottom .footer-copyright-wrap .footer-local-logo {
                display: block;
                float: right;
                width: 68.89%;
                padding: 0 0 4.44444444vw
            }

            .footer .footer-bottom .footer-copyright-wrap .footer-local-logo--wrap {
                display: inherit;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-flow: inherit;
                flex-flow: inherit;
                -webkit-box-align: inherit;
                -ms-flex-align: inherit;
                align-items: inherit;
                font-size: inherit
            }

            .footer .footer-bottom .footer-copyright-wrap .footer-local-logo__item {
                display: -webkit-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
                float: right;
                text-align: right;
                margin-bottom: 3.33333333vw;
                max-width: 52.22222222vw
            }

                .footer .footer-bottom .footer-copyright-wrap .footer-local-logo__item:nth-child(n+1) {
                    margin-left: 6.66666667vw
                }

                .footer .footer-bottom .footer-copyright-wrap .footer-local-logo__item:last-of-type {
                    margin-bottom: 0
                }

        .footer .footer-bottom .footer-language-wrap {
            display: inline-table;
            height: auto;
            padding-left: 0;
            width: 100%
        }

            .footer .footer-bottom .footer-language-wrap .footer-sns {
                display: block;
                width: 100%;
                text-align: center;
                margin-right: 0;
                margin-left: -1.11111111vw
            }

            .footer .footer-bottom .footer-language-wrap .footer-sns__title {
                display: block;
                width: 100%;
                height: auto;
                text-align: center;
                font-size: 3.33333333vw;
                padding: 2.22222222vw 0;
                line-height: 1.37
            }

            .footer .footer-bottom .footer-language-wrap .footer-sns__list {
                display: table;
                margin: 0 auto
            }

            .footer .footer-bottom .footer-language-wrap .footer-sns__item {
                display: table-cell;
                margin-left: 0
            }

            .footer .footer-bottom .footer-language-wrap .footer-sns__link {
                margin: 2.22222222vw;
                width: 6.66666667vw;
                height: 6.66666667vw
            }

            .footer .footer-bottom .footer-language-wrap .footer-language {
                position: absolute;
                display: inline-block;
                height: auto;
                padding-right: 0
            }

                .footer .footer-bottom .footer-language-wrap .footer-language:after {
                    display: none
                }

            .footer .footer-bottom .footer-language-wrap .footer-language__anchor {
                display: inline-block;
                position: relative;
                padding-right: 3.88888889vw;
                font-size: 3.33333333vw
            }

                .footer .footer-bottom .footer-language-wrap .footer-language__anchor:after {
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    width: 3.05555556vw;
                    height: 3.05555556vw;
                    background: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon_footer_language.svg) no-repeat;
                    content: ""
                }

                .footer .footer-bottom .footer-language-wrap .footer-language__anchor:focus:after, .footer .footer-bottom .footer-language-wrap .footer-language__anchor:hover:after {
                    background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon_footer_language_active.svg)
                }

            .footer .footer-bottom .footer-language-wrap .footer-language__panel {
                position: fixed;
                width: 100%;
                padding: 0 6.66666667vw;
                left: 0;
                bottom: 0
            }

            .footer .footer-bottom .footer-language-wrap .footer-language__dimmed {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 300;
                width: 100%;
                height: 100%;
                background-color: #000;
                opacity: .85
            }

            .footer .footer-bottom .footer-language-wrap .footer-language__content {
                position: relative;
                right: 0;
                font-size: 3.33333333vw;
                bottom: 17.77777778vw;
                z-index: 500
            }

                .footer .footer-bottom .footer-language-wrap .footer-language__content:after {
                    display: none
                }

            .footer .footer-bottom .footer-language-wrap .footer-terms {
                padding: 0;
                border-bottom: none;
                height: auto;
                width: 100%
            }

            .footer .footer-bottom .footer-language-wrap .footer-terms__list {
                text-align: center
            }

            .footer .footer-bottom .footer-language-wrap .footer-terms__link {
                font-size: 3.33333333vw;
                padding: 2.22222222vw 3.33333333vw;
                line-height: 1.37
            }
}

.footer .footer-column {
    display: table;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    border-top: 1px solid #ccc
}

.footer .footer-column__item {
    display: table-cell;
    padding-bottom: 24px;
    border-bottom: 1px solid #ccc;
    width: 25%
}

    .footer .footer-column__item:nth-child(n+2) {
        border-left: 1px solid #d9d9d9
    }

.footer .footer-column__item--flex ~ .footer-column__item {
    width: 20%
}

/*.footer .footer-column__item--flex {
    width: 40%
}*/

    .footer .footer-column__item--flex .footer-category__list {
        width: 50%
    }

        .footer .footer-column__item--flex .footer-category__list + .footer-category__list {
            /*position: absolute;*/
            top: 56px;
            left: 50%
        }

.footer .footer-column__item .footer-category {
    position: relative
}

.footer .footer-column__item .footer-category__title {
    display: block;
    height: 56px;
    padding: 16px 24px;
    font-size: 18px;
    line-height: 1.33;
    font-weight: 700
}

.footer .footer-column__item .footer-category__anchor {
    display: none
}

.footer .footer-column__item .footer-category__link {
    display: block;
    padding: 7px 24px;
    font-size: 14px;
    line-height: 1.33;
    color: #000
}

    .footer .footer-column__item .footer-category__link:focus, .footer .footer-column__item .footer-category__link:hover {
        color: #1428a0
    }

    .footer .footer-column__item .footer-category__link.new-window[target=_blank]:after {
        content: "";
        display: inline-block;
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-black.svg);
        background-repeat: no-repeat;
        background-size: 100% auto;
        vertical-align: middle
    }

    .footer .footer-column__item .footer-category__link.new-window[target=_blank]:active:after, .footer .footer-column__item .footer-category__link.new-window[target=_blank]:focus:after, .footer .footer-column__item .footer-category__link.new-window[target=_blank]:hover:after {
        background-image: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-new-window-navy.svg)
    }

    .footer .footer-column__item .footer-category__link.new-window[target=_blank]:after {
        width: 14px;
        height: 14px;
        margin-left: 5px
    }

@media only screen and (min-width:769px) and (max-width:1440px) {
    .footer .footer-column__item {
        padding-bottom: 1.66666667vw
    }

    .footer .footer-column__item--flex .footer-category__list {
        height: 17.77777778vw
    }

        .footer .footer-column__item--flex .footer-category__list + .footer-category__list {
            top: 3.88888889vw
        }

    .footer .footer-column__item--flex .footer-category__item {
        height: 2.22222222vw
    }

    .footer .footer-column__item .footer-category__title {
        height: 3.88888889vw;
        padding: 1.11111111vw 1.66666667vw;
        font-size: 1.25vw
    }

    .footer .footer-column__item .footer-category__link {
        padding: .48611111vw 1.66666667vw;
        font-size: .97222222vw
    }

        .footer .footer-column__item .footer-category__link.new-window[target=_blank]:after {
            width: .97222222vw;
            height: .97222222vw;
            margin-left: .34722222vw
        }
}

@media only screen and (max-width:768px) {
    .footer .footer-column {
        table-layout: auto
    }

    .footer .footer-column__item {
        display: block;
        position: relative;
        border: none;
        padding-bottom: 0
    }

        .footer .footer-column__item:after {
            content: "";
            display: block;
            width: auto;
            height: 1px;
            background-color: #e7e7e7;
            margin: 0 24px
        }

        .footer .footer-column__item:nth-child(n+2) {
            border-left-width: 0
        }

        .footer .footer-column__item > .footer-category__list {
            padding-bottom: 2.77777778vw
        }

        .footer .footer-column__item .footer-category__list-wrap {
            display: none;
            overflow: hidden
        }

    .footer .footer-column__item--flex, .footer .footer-column__item--flex ~ .footer-column__item {
        width: 100%
    }

        .footer .footer-column__item--flex .footer-category__list {
            width: 100%;
            height: auto
        }

            .footer .footer-column__item--flex .footer-category__list + .footer-category__list {
                position: static
            }

        .footer .footer-column__item--flex .footer-category__item {
            display: block;
            width: 100%;
            height: auto
        }

    .footer .footer-column .footer-category__title {
        height: 15.55555556vw;
        padding: 4.72222222vw 6.66666667vw;
        font-size: 4.44444444vw
    }

    .footer .footer-column .footer-category__anchor {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 15.55555556vw
    }

        .footer .footer-column .footer-category__anchor + .footer-category__list-wrap {
            display: none
        }

        .footer .footer-column .footer-category__anchor:after {
            display: block;
            position: absolute;
            top: 50%;
            right: 6.38888889vw;
            width: 7.5vw;
            height: 7.5vw;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            background: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-regular-navigation-open-down.svg) no-repeat 50%;
            background-size: 100%;
            content: ""
        }

    .footer .footer-column .footer-category__anchor--active:after {
        background: url(https://twsamsungcampaign.azureedge.net/common_api/images/icon-regular-navigation-close-up.svg) no-repeat 50%;
        background-size: 100%
    }

    .footer .footer-column .footer-category__anchor--active + .footer-category__list, .footer .footer-column .footer-category__anchor--active + .footer-category__list + .footer-category__list {
        height: auto
    }

    .footer .footer-column .footer-category__anchor--active + .footer-category__list-wrap {
        display: block
    }

    .footer .footer-column .footer-category__anchor--active + .footer-category__list:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #fff
    }

    .footer .footer-column .footer-category__link {
        height: 11.11111111vw;
        padding: 3.05555556vw 8.61111111vw;
        font-size: 3.88888889vw;
        line-height: 1.36
    }

        .footer .footer-column .footer-category__link.new-window[target=_blank]:after {
            width: 3.88888889vw;
            height: 3.88888889vw;
            margin-left: 1.38888889vw
        }
}
