.whale-container-absolute {
    width: 1024px;
    height: 100%;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whale-image-container {
    width: 80%;
    height: 80%;
}
.whale-title {
    margin-bottom: 50px;
}

.whale-title-text {
    font-size: 4rem;
    margin: 0px;
    text-align: center;
}

.description-whale {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0%;
    z-index: 4;
}

.steps-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.peanut-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.peanut {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bubble-1 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

.bubble-2 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;;
}

.bubble-3 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    z-index: 99999;
}

.bubble {
    width: 40px;
    height: 40px;
    border: 2px solid rgb(104	174	249);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.peanut-new {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}


.bubble-text {
    color: black;
    font-weight: bolder;
}

  
@media (max-width: 1399.98px) { 

}

@media (max-width: 1199.98px) { 

}

@media (max-width: 991.98px) { 
 
}


@media (max-width: 767.98px) { 
    .whale-title-text {
        font-size: 4rem;
    }
    .steps-container {
        justify-content: flex-start;
        /* margin-bottom: 20px; */
    }
    .peanut-container {
        padding-left: 20px;
        display: flex !important;
        justify-self: flex-start;
    }
}


@media (max-width: 575.98px) { 
    .whale-title-text {
        font-size: 6vw;
        text-align: center;
    }
    .steps-container {
        justify-content: flex-start;
        /* margin-bottom: 20px; */
    }
    .peanut-container {
        display: flex !important;
        justify-self: flex-start;
    }
}



