.custom-footer-container {
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.footer-text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.custom-footer-text {
    font-size: 4rem;
    margin: 0px;
}

.pez-aleta-2 {
    width: 500px;
    position: absolute;
    top: -100px;
    left: -200px;
}

.pez-gordito-2 {
    width: 500px;
    position: absolute;
    bottom: -100px;
    right: -200px;
}

@media (max-width: 1399.98px) { 

}

@media (max-width: 1199.98px) { 

}

@media (max-width: 991.98px) { 
    .custom-footer-text {
        font-size: 2.5rem
    }
    .pez-aleta-2 {
        width: 400px;
        position: absolute;
        top: -100px;
        left: -200px;
    }
    .pez-gordito-2 {
        width: 400px;
        position: absolute;
        bottom: -100px;
        right: -200px;
    }
}

@media (max-width: 767.98px) { 
    .custom-footer-text {
        font-size: 2.3rem
    }
    .pez-aleta-2 {
        width: 300px;
        position: absolute;
        top: -100px;
        left: -100px;
    }
    .pez-gordito-2 {
        width: 400px;
        position: absolute;
        bottom: -100px;
        right: 0px;
    }

}

@media (max-width: 575.98px) { 
    .custom-footer-text {
        font-size: 6vw;
    }
    .pez-aleta-2 {
        width: 200px;
        position: absolute;
        top: -50px;
        left: -80px;
    }
    .pez-gordito-2 {
        width: 300px;
        position: absolute;
        bottom: -100px;
        right: -100px;
    }
}
