.upload {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    margin-top: 100px;
    position: relative;
    max-width: 1024px;
}

.upload-container {
    border: 1px solid #e4e2e2dd;
    width: 60vw;
    height: 80%;
    border-radius: 20px;
    padding: 0px;
}

.inside-upload {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload-text {
    margin: 0px;
    font-weight: bolder;
    font-size: 1rem;
}

#form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-width: 2px; */
    /* border-radius: 1rem;
    border-style: dashed; */
    /* border-color: #cbd5e1; */
    /* background-color: #f8fafc; */
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background: rgba(255, 255, 255, 0.667);
    padding: 10px;
    border-radius: 10px;

}

.upload-button:hover {
    text-decoration-line: underline;
}

#label-file-upload.drag-active {
    background-color: #ffffff;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.image1-upload {
    position: absolute;
    top: -130px;
    width: 500px;
    right: -200px;
    z-index: 3;
}

.image2-upload {
    position: absolute;
    bottom: -200px;
    right: -50px;
    width: 500px;
    z-index: 3;
}

.image3-upload {
    position: absolute;
    top: -200px;
    left: 0px;
    width: 500px;
    z-index: 3;
}


@media (max-width: 1399.98px) {

}

@media (max-width: 991.98px) { 
    .image1-upload {
        width: 400px;
        right: -100px;
        top: -100px;
    }
    .image2-upload {
        position: absolute;
        bottom: -150px;
        right: 50px;
        width: 400px;
        z-index: 3;
    }
    .image3-upload {
        top: -150px;
        left: 100px;
        width: 400px;
    }
}

@media (max-width: 767.98px) {
    .upload-container {
        height: 500px;
    }
}

@media (max-width: 575.98px) {
    .upload-container {
        height: 300px;
        width: 100%;
    }
    .upload-text {
        font-size: 16px;
    }
    .image1-upload {
        width: 300px;
        right: -150px;
        top: -80px;
    }
    .image2-upload {
        position: absolute;
        bottom: -100px;
        right: -50px;
        width: 300px;
        z-index: 3;
    }
    .image3-upload {
        top: -130px;
        left: -30px;
        width: 300px;
    }

}
