/* fonts */

/* @font-face {
  font-family: "samsung";
  src: local("samsung"),
  url("./assets/fonts/samsung.ttf") format("truetype");
} */

@font-face {
  font-display: swap;
  font-family: SamsungOne;
  font-style: normal;
  font-weight: 400;
  src: local("SamsungOne"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-400.woff2) format("woff2"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-400.woff) format("woff"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-400.ttf) format("truetype"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-400.eot?#iefix) format("embedded-opentype")
}

@font-face {
  font-display: swap;
  font-family: SamsungOne;
  font-style: normal;
  font-weight: 700;
  src: local("SamsungOne"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-700.woff2) format("woff2"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-700.woff) format("woff"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-700.ttf) format("truetype"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungOne-700.eot?#iefix) format("embedded-opentype")
}

 @font-face {
  font-display: swap;
  font-family: SamsungSharpSans;
  font-style: normal;
  font-weight: 700;
  src: local("SamsungSharpSans"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungSharpSansBd.woff2) format("woff2"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungSharpSansBd.woff) format("woff"),url(https://twsamsungcampaign.azureedge.net/common_api/fonts/SamsungSharpSansBd.eot?#iefix) format("embedded-opentype")
}

body {
  font-family: 'Noto Sans TC';
}

.showOptions img {
  transform: rotate(180deg)
}

.samsung_font {
  font-family: 'SamsungSharpSans';
}

.samsung-one {
  font-family: 'SamsungOne';
}

/* video  container */
.video-container {
  display: flex;
  background-color: black;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 150px; */
  position: relative;
  width: 100vw;
}

.video-container img {
  margin-top: -20px;
}

.down-mobile-responsive {
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;
  bottom: -10px;
  border: none;
}

.presentation-inner-container {
  width: 80%;
  height: 100%;
  display: flex;
  margin-left: 100px;
}

.presentation-text-container {
  padding: 100px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.first-carousel-presentation {
  margin-bottom: 50px;
}

.presentation-text {
  margin: 0px;
}

.down-animation {
  display: none
}

.down-animation img {
  animation: action 3s infinite;
}

.down-mobile-responsive img {
  width: 35px;
  height: 35px;
}

.up-mobile-responsive {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}

.up-mobile-responsive img {
  width: 35px;
  height: 35px;
  animation: action-up 3s infinite;
}

.video-container-whale {
  display: flex;
  background-color: black;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 100px;
  height: 1000px;
}

/*  */

/* slider background */
.slider-background {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-image {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  z-index: 1;
}

.bottom-image {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} 

.top-image .bottom-image img {
  vertical-align: middle;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

/*  */

/* images */
.image1 {
  position: absolute;
  width: 300px;
  left: 100px;
  bottom: -200px;
}

.image2 {
  position: absolute;
  width: 400px;
  right: 0px;
  bottom: -250px;
}

.image3 {
  position: absolute;
  width: 100px;
  right: -50px;
  bottom: -200px;
}

.image4 {
  position: absolute;
  width: 70px;
  right: 30%;
  top: -50px;
  margin: auto;
}

.image5 {
  position: absolute;
  width: 300px;
  left: 0px;
  top: -150px;
}

.special-image {
  position: absolute;
  width: 500px;
  left: -180px;
  top: 50px;
}

.image6 {
  position: absolute;
  width: 150px;
  right: -200px;
  top: -30px;
}

.image7 {
  position: absolute;
  width: 130px;
  left: -80px;
  bottom: 50px;
}

.image8 {
  position: absolute;
  width: 150px;
  left: -180px;
  bottom: -80px;
}

.image9 {
  position: absolute;
  width: 100px;
  left: 150px;
  top: 0px;
}

.image10 {
  position: absolute;
  width: 150px;
  left: 30px;
  top: 150px;
}

/*  */


/* presentation container */

.presentation-container {
  height: auto;
  display: flex;
  margin-bottom: 100px;
  position: relative;
}

/* carousel container left side */
.carousel-options-container {
  height: 100%;
  /* display: flex; */
  flex: 1;
  flex-direction: column;
}

.carousel-image-container {
  height: 100%;
  width: 40%;
  background-color: gray;
  border-radius: 10px;
  position: relative;
}

.inside-carousel-container {
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  /* margin-bottom: 20vh; */
}

.final-text-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.first-carousel-container {
  display: flex;
  justify-content: center;
  padding: 0px;
  margin-bottom: 30vh;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
}

.changes-container {
  margin-bottom: 30vh;
  position: relative;
}

.galaxy-tab-container {
  margin-bottom: calc(30vh - 50px);
}
.image-container {
  align-items: center;
  justify-content: center;
  display: flex;
}
.child-tab-image-container {
  display: flex;
  flex: 3;
  padding: 10px;
}

.child-tab-text-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
  justify-content: flex-end;
}

.child-tab-text-container img {
  width: 30px;
  height: 30px;
  margin-right: 10px
}

div .text-container {
	width: 50%;
	max-width: 4	00px;
}

.option-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 14px;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items :center;
  position: relative;
  overflow: hidden ;
}

.option-container-behind {
  width: 100%;
  height: 100%;
  background-color: rgb(215, 225, 221);
  position: absolute;
  z-index: -1;
  animation: move 1s;
}

.dots-option {
  width: 20px;
  height: 20px;
  margin: 10px;
  border-radius: 100px;
}

.dots-option:hover {
  cursor: pointer;
}

.option-container:hover {
  cursor: pointer;
}

.option-container img {
  width: 60%;
}

/*  */

/* text */
.font-size-text {
  font-size: 2vw;
}

.bubble-text {
  color: white;
}

.tab-text {
  margin: 0px;
}

.bubble-1 {
  left: 0%;
}
.bubble-2 {
  top: 5%;
  right: 0%;
}
.bubble-3 {
  bottom: 5%;
  right: 0%;
}

.description-whale {
  top: -80px;
}

/*  */

/* second video container */
.second-video-container {
  margin-bottom: 30vh;
  position: relative;
  display: flex;
  padding: 0px;
}

.second-video {
  max-width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 10vw;
}

/* whale container */
.whale-container {
  max-width: 1024px; 
  padding: 30px;
  position: absolute;
}

.whale-presentation {
  margin-bottom: 10vh;
  /* margin-bottom: 100px; */
}

.whale-button {
  background-color: white;
  width: 150px;
  padding: 10px;
  text-align: center;
  border-radius: 20px;
  margin-bottom: 10px;
}

/* experience container */

.experience-container {
  margin-bottom: 10vh;
}

.tablet-full-container {
  display: flex;
  width: 80%;
  padding: 10px;
}

.tablet-container {
  display: flex;
  flex: 1;
  padding: 10px;
  justify-content: center;
}

.tablet-container img {
  max-width: 100%;
}

.tablet-des-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
}

/* marquee */
.marquee-presentation-container {
  margin-bottom: 230px;
  display: flex;
  position: relative;
  justify-content: center;
}

.marquee-content-container {
  width: 80%;
  position:relative;
}

.marquee-height {
  height: 500px;
  margin-bottom: 250px;
  margin-top: 150px;
}

.marquee-inside-container {
  display: flex;
  height: 500px;
  justify-content: center;
  align-items: center;
}

.marquee-sections {
  display: flex;
  flex: 1;
  width: 100vw;
  /* width: 1600px; */
  border-radius: 20px;
}

.marquee-images {
  display: flex;
  flex: 1;
  margin: 10px;
}

.marquee-images img {
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.marquee-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  border-radius: 20px;
  position: absolute;
}

.marquee-text-container p {
  color: white;
  margin: 0px;
  font-size: 1rem;
  font-weight: bold;
}
/*  */

.register-button-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 200px; */
}

.footer-image-container {
  margin-bottom: 0px;
  padding: 0px !important;
  margin-bottom: 100px;
  position: relative;
}


.section-whale-container {
  border-radius: 5px;
  background-color: rgba(255,255,255,.5);
}

.section-fixed-text {
  margin: 0px;
  color: black;
  font-weight: bolder;
  font-size: 1rem;
  margin-right: 10px;
}

.section-fixed-text:hover {
  cursor: pointer;
}

/* down animation */

.options-container-animation {
  position: fixed;
  z-index: 10000;
  right: 0px;
  margin: auto;
  top: 50%; 
}

.options-container-animation.hide {
  transform: translateX(100%);
}

.options-container-animation.hideOptions {
  animation: hideOptions 1s forwards;
}

.options-container-animation.showOptions {
  animation: showOptions 1s forwards;
}

@keyframes showOptions {
  0% {
    transform: translateX(100%)
  }
  100% {
    transform: translateX(0%)
  }
}

@keyframes hideOptions {
  0% {
    transform: translateX(0%)
  }
  100% {
    transform: translateX(100%)
  }
}

.arrow.first {
  animation: moveLeft .3s ease infinite;
}

.arrow.second {
  animation: moveRight .3s ease forwards;
}

@keyframes moveRight {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(73px)
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(73px)
  }
  100% {
    transform: translateX(0)
  }
}

@keyframes action {
  0% { transform: translateY(0px); }
  100% { transform: translateY(10px); }
}

@keyframes action-up {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

@keyframes move {
  0% { right: 100%; }
  100% { right: 0%; }

}

.slider-background {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whale-container {
  width: 80%;
  height: 80%;
  padding: 30px;
  position: absolute;
}


.top-image {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  z-index: 1;
}

.bottom-image {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} 

.top-image .bottom-image img {
  vertical-align: middle;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.handle {
  position: absolute;
  height: 70%;
  width: 7px;
  background-color: rgb(104	174	249);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  cursor: col-resize;
  user-select: none;
}

.handle-arrow::before, .handle-arrow::after {
  border-right: 5px solid rgb(104	174	249	);
  content: '';
  display: block;
  height: 19px;
  margin-top: -12px;
  position: absolute;
  transform: rotate(135deg);
  left: 20px;
  top: 50%;
  width: 0;
}

.handle-arrow.left {
  transform: rotate(180deg) translateY(-6px) translateX(-6px)
}

.handle-arrow::after {
  margin-top: -1px;
  transform: rotate(45deg);
}

.handle_block {
  display: block;
  position: absolute;
  top: calc(50% - 15px);
  right: calc(50% - 15px);
  height: 30px;
  width: 30px;
  background: white;
  border-radius: 8px;
  padding: 2px;
  user-select: none;
}

.whale-button {
  background-color: white;
  width: 150px;
  padding: 10px;
  text-align: center;
  border-radius: 20px;
  margin-bottom: 20px;
  border:none;
}


.left-side-bottom-carousel {
  display: flex;
  flex: 1;
}

.right-side-bottom-carousel {
  display: flex;
  flex: 3;
  justify-content: center;
}

@media (max-width: 575.98px) {
  .option-container {
    padding: 7px;
  }
  .option-container p {
    font-size: .8rem;
  }
  .down-animation {
    display: flex;
  }

  .marquee-height {
    height: 500px;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .video-container-whale {
    margin-bottom: 50px;
  }

  .presentation-container {
    margin-bottom: 50px;
  }
  
  .video-container img {
    margin-top: -20px;
  }
}