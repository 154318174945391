
/* media query */

.download-whale-container {
  z-index: 999999999;
  margin-top: -50px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  /* zIndex:99999999,marginTop: "-130px",justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", width: "100%", position: "relative" */
}
@media (max-width: 1399.98px) {
  .inside-carousel-container {
    /* width: 100%; */
    /* background-color: green; */
  }
  .image5 {
    width: 300px;
    left: -100px;
  }
  .special-image {
    width: 400px;
    left: -250px;
    top: 80px;
  }
  .image8 {
    left: -150px;
    width: 130px;
  }
  .marquee-sections {
    width: 1600px;
  }
}

@media (max-width: 1199.98px) { 
  .image5 {
    width: 250px;
    left: -40px;
  }
  .special-image {
    width: 400px;
    left: -200px;
    top: 40px;
  }
  .second-video {
  }
  .image8 {
    left: -130px;
    width: 130px;
  }
  .image7 {
    position: absolute;
    width: 100px;
    left: -50px;
    bottom: 50px;
  }
  .image8 {
    position: absolute;
    width: 130px;
    left: -140px;
    bottom: -80px;
  }
  .image9 {
    position: absolute;
    width: 100px;
    left: 200px;
    top: 0px;
  }
  .image10 {
    position: absolute;
    width: 120px;
    left: 80px;
    top: 100px;
  }
}

@media (max-width: 991.98px) { 
  .marquee-height {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .video-container-whale {
    margin-bottom: 50px;
  }
  .download-whale-container {
    margin-top: -80px;
  }

  .marquee-presentation-container {
    /* margin-bottom: 30vh; */
  }
  .inside-carousel-container {
    flex-direction: column-reverse
  }
  .inside-carousel-wrapper-1 > div {
    flex-direction: row;
    display: flex;
    align-items: start;
  }
  .inside-carousel-wrapper-2 > div {
    height: 500px !important;
    max-height: calc(100vw - 50px);
  }
  .dots-option {
    width: 12px;
    height: 12px;
    margin: 8px;
  }

  .presentation-inner-container {
    width: 100%;
    margin-left:0px;
  }

  .image1 {
    width: 200px;
  }
  .image2 {
    width: 400px;
    bottom: -200px;
    
  }
  .image3 {
    width: 80px;
    bottom: -150px;
    right: -40px;
  }
  .inside-carousel-container {
    width: 100%;
  }
  .image5 {
    width: 200px;
    left: -80px;
    top: -100px;
  }
  .image4 {
    width: 40px;
  }
  .special-image {
    width: 300px;
    left: -200px;
    top: 50px;
  }
  .second-video {
    max-width: 80%;
  }
  .image6 {
    width: 150px;
    right: -80px;
    top: -100px;
    
  }
  .image8 {
    left: -120px;
    width: 130px;
  }
  .whale-container {
    width: 80%;
    padding: 20px;
    margin-top: 100px;
  }
  .bubble { 
    width: 40px;
    height: 40px;
  }
  .bubble-1 {
    left: 0%;
    top: 5%;
  }
  .bubble-2 {
    top: 18%;
    right: 20%;
  }
  .bubble-3 {
    top: 75%;
    right: 30%;
  }
  .tablet-full-container {
    width: 100%;
  }
  .first-carousel-container {
    max-width: 80vw !important;
  }
  .description-whale {
    right: 5%;
  }
  .video-container {
    margin-bottom: 100px;
  }
  .presentation-text-container {
    padding: 30px;
  }
  .marquee-presentation-container {
    margin-bottom: 165px;
  }
  .second-video-container {
    margin-bottom: 15vh;
  }
  .experience-container {
    margin-bottom: 5vh;
  }
  .galaxy-tab-container {
    margin-bottom: 10vh;
  }
  .first-carousel-presentation {
    margin-bottom: 5vh;
  }
  .first-carousel-container {
    margin-bottom: 15vh;
  }
  .changes-container {
    margin-bottom: 15vh;
  }
  .image9 {
    position: absolute;
    width: 60px;
    left: 150px;
    top: 0px;
  }
  .image10 {
    position: absolute;
    width: 80px;
    left: 80px;
    top: 50px;
  }
  .child-tab-image-container {
    flex: 2
  }
  .child-tab-text-container img {
    width: 20px;
    height: 20px;
  }

  .video-container-whale {
    height: 900px;
  }

}

@media (max-width: 767.98px) { 
  .video-container {
    margin-bottom: 15vh;
  }
  .container-fluid.presentation-container {
    height: fit-content
  }

  .inside-presentation-wrapper > div {
    width: 100% !important;
  }

  .presentation-text-container {
    padding: 50px;
  }
  .inside-presentation-wrapper .text-container {
    padding-right: 20px !important;
  }
  .marquee-presentation-container {
    margin-bottom: 165px;
  }
  .second-video-container {
    margin-bottom: 15vh;
  }
  .experience-container {
    margin-bottom: 5vh;
  }
  .galaxy-tab-container {
    margin-bottom: 10vh;
  }
  .whale-presentation {
    margin-bottom: 5vh;
  }
  .first-carousel-presentation {
    margin-bottom: 5vh;
  }
  .first-carousel-container {
    margin-bottom: 15vh;
  }
  .changes-container {
    margin-bottom: 15vh;
  }
  .image9 {
    position: absolute;
    width: 60px;
    left: 100px;
    top: 0px;
  }
  .image10 {
    position: absolute;
    width: 80px;
    left: 50px;
    top: 80px;
  }
  .image1 {
    width: 130px;
    left: -30px;
    bottom: -80px;
  }
  .image2 {
    width: 300px;
    bottom: -150px;
    right: -100px;
  }
  .video-container {
    /* margin-bottom: 100px; */
  }
  .carousel-image-container {
    width: 100%;
  }
  .image3 {
    width: 80px;
    right: -30px;
    bottom: -150px;
  }
  .first-carousel-container {
    max-width: 100vw !important;
  }
  .image5 {
    width: 180px;
    left: -100px;
    top: -100px;
  }
  .special-image {
    width: 300px;
    left: -200px;
  }
 
  .second-video-container {
    justify-content: center;
  }
  .second-video {
    max-width: 80%;
    margin-left: 0px;
  }
  .image8 {
    position: absolute;
    width: 130px;
    left: -100px;
    bottom: -80px;
  }
  .tablet-full-container {
    width: 100%;
  }
  .tab-text {
    font-size: 2vw;
  }
  .description-whale {
    right: 5%;
  }
  
  /*By CL*/
  .handle {
    height: 250px;
    width: 5px; 
  }
  .video-container-whale {
    height: 800px;
  }

  .whale-container {
    width: 100%;
    padding: 20px;
  }
}

@media (max-width: 575.98px) {
  
  .inside-presentation-wrapper .image-container {
    padding: 10px;
  }
  .experience-wrapper {
    flex-direction: column;
  }
  .experience-wrapper > div {
    width: 100% !important;
    margin-left: 0px;
    margin:0px;
  }

  .inside-presentation-wrapper {
    flex-direction: column-reverse;
  }
}