body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
div {
  white-space: pre-line;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gb-footer__lang-select {
  display: none;
  position: absolute;
  /* right: -20px; */
  bottom: 50%;
  margin-bottom: -22px;
  padding: 14px 19px;
  min-width: 307px;
  max-width: 1440px;
  border: 1px solid #d9d9d9;
  background: #fff;
  text-align: left;
  z-index: 1200;
}

.gb-footer__lang-select a {
  display: block;
  position: relative;
  margin-top: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 0;
  color: #000;

  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
}

.footer-sns {
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
  }
}
